import React from 'react';
import styled from 'styled-components';
import { ProgressBar } from 'react-bootstrap';

import { useSharedContext } from 'Lib/hooks/useSharedContext';

import HVCenteredFlex from 'App/styled/HVCenteredFlex';
import contexts from 'App/contexts';

const LoadingBackground = styled(HVCenteredFlex)`
    background-color: rgba(0, 0, 0, 0.95);
    z-index: 10000;
`

const LoadingWindow = styled.span`
    display: inline-block;
    width: 50%;
    height: fit-content;
    max-width: 360px;
    text-align: center;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 24px;
    border-radius: 8px;
`

function LoadingScreen() {
    const loadingScreenContext = useSharedContext(contexts.LoadingScreen);
    
    if (!loadingScreenContext.active) return null; 

    return (
        <LoadingBackground>
            <LoadingWindow>
                <span> {loadingScreenContext.message} </span>
                <ProgressBar 
                    id="progress-bar"
                    animated 
                    striped 
                    label={`${(loadingScreenContext.progress * 100).toFixed(0)}%`}
                    now={loadingScreenContext.progress}
                    max={1}
                    style={{
                        backgroundColor: 'lightgray',
                        marginTop: 12,
                        width: '100%',
                        height: 16,
                    }}
                />
            </LoadingWindow>
        </LoadingBackground>
    )
}


export default LoadingScreen;