import styled from 'styled-components';

const HVCenteredFlex = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    min-height: 0;
`

export default HVCenteredFlex;