import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { useSharedContext, useSetSharedContext } from 'Lib/hooks/useSharedContext';
import contexts from 'App/contexts';

const Container = styled.div`
    position: relative;
    padding: 8px;
    background-color: rgba(0, 8, 20, 0.85);
    border-radius: 4px;
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 100;
    width: fit-content;
    font-size: 10px;
`

const Td = styled.td`
    min-width: 80px;
    max-width: 160px;
`

function Property({ name, value }) {
    return React.useMemo(() => (
        <React.Fragment>
            <Td className="text-light">{ name || '' }</Td>
            <Td className="text-info">{ value || '' }</Td>
        </React.Fragment>
    ), [ name, value, ]);
}
    
function TableRow({ left, right }) {
    return (
        <tr>
            <Property name={left[0]} value={left[1]} />
            <Property name={right[0]} value={right[1]} />
        </tr>
    )
}

function AssetInfo() {
    const { selected } = useSharedContext(contexts.Assets);

    return React.useMemo(() => {
        const { properties } = selected;
        const propertyNames = Object.keys(properties).sort().filter(x => x !== "DocumentLi");
        const rowData = _.chunk(propertyNames, 2);

        if (selected) {
            return (
                <Container>
                    <table className='table table-sm' style={{ tableLayout: 'auto', }}>
                        <tbody>
                            {
                                rowData.map(([k1, k2], i) => {
                                    return <TableRow key={i} left={[ k1, properties[k1], ]} right={k2 ? [ k2, properties[k2], ] : [ null, null, ]}/>
                                })
                            }
                        </tbody>
                    </table>
                </Container>
            )
        }
        else {
            return null;
        }

    }, [ selected, ]);
}

export default AssetInfo;