import React from 'react';


import IFCModel from 'App/mapbox/3d-models/ifc-model';
import useChainedPromise from 'Lib/hooks/useChainedPromise';
import { useSharedContext, setSharedContext } from 'Lib/hooks/useSharedContext';

import contexts from 'App/contexts';
import config from 'App/config';

function onProgressHook(text) {
	return e => {
		setSharedContext(contexts.LoadingScreen, {
			progress: e.loaded / e.total,
			message: text,
		})
	}
}

function useTerminalModel(options, preLoad, deps=[]) {
	const model = React.useRef();
	const chainedPromise = useChainedPromise();

	React.useEffect(() => {
		const onLoad = preLoad();
		
		chainedPromise
			.then(async () => {
				if (!model.current) {
					const model = new IFCModel();
					model.current = await model.load(options);

					return onLoad(model.current)
				}
				else {
					return onLoad(model.current)
				}
			})

		return () => {
			chainedPromise
				.then(onDispose => {
					if (onDispose) onDispose();
				})
		}
	}, deps)
}

function TerminalModel({ map, customLayer }) {
	const { model, show3DModel, showWireframe } = useSharedContext(contexts.TerminalModel);
	
	useTerminalModel({
		...config.TerminalModelOptions,
		onProgress: onProgressHook('Loading Terminal Model...'),
	}, () => {
		setSharedContext(contexts.LoadingScreen, { active: true })
		
		return model => {
			setSharedContext(contexts.TerminalModel, { model, });
			setSharedContext(contexts.LoadingScreen, { active: false })
			
			return () => {
				setSharedContext(contexts.TerminalModel, { model: null, })
			}
		}
	})

	React.useEffect(() => {
		if (customLayer && model) {
			customLayer.addModel(model.model);
			customLayer.addModel(model.wireframe);
			
			if (map) {
				map.triggerRepaint();
			}
			
			return () => {
				customLayer.removeModel(model.model);
				customLayer.removeModel(model.wireframe);
			}
		}
	}, [map, customLayer, model])

	React.useEffect(() => {
		if (model) {
			model.toggle3DModelVisibility(show3DModel);
			
			if (map) {
				map.triggerRepaint();
			}
		}
	}, [map, model, show3DModel])

	React.useEffect(() => {
		if (map && model) {
			model.toggleWireframeVisibility(showWireframe);

			if (map) {
				map.triggerRepaint();
			}
		}
	}, [map, model, showWireframe])
	
	return null;
}


export default TerminalModel;