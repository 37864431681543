import React from 'react';

import AssetManager from 'App/mapbox/asset-manager';

function useAssetManager(map, assets, options) {
    const [ assetManager, setAssetManager ] = React.useState(null);
    
    React.useEffect(() => {
        if (map && assets.length > 0) {
            const manager = new AssetManager({
                map,
                assets,
                ...options,
            });

            setAssetManager(manager)

            return () => {
                manager.dispose();
                setAssetManager(null);
            }
        }
    }, [map, assets])
    
    return assetManager;
}

export default useAssetManager;