import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import { compute2DConvexHullCW, checkPointInConvexPolyCW, } from 'Lib/geom';
import { getAssets } from "App/actions";

function useAssets(model) {
    const [ assets, setAssets ] = React.useState([]);
    
    React.useEffect(() => {
        getAssets()
            .then(assets => {
                assets.features.forEach(feature => {
                    feature.id = feature.properties.OBJECTID;
                    
                    if (feature.geometry.type === 'Polygon') {
                        feature.geometry.coordinates = [feature.geometry.coordinates];
                        feature.geometry.type = "MultiPolygon";
                    }
                })
                
                setAssets(assets.features);
            })
    }, [])
    
    const assetsWithEnclosure = useAssetsWithEnclosure(assets, model);
    const assetsWithInundationPeriods = useAssetsWithInundationPeriods(assetsWithEnclosure, model);
    
    return assetsWithInundationPeriods;
}

function useAssetsWithEnclosure(assets, model) {
    const [ assetsWithEnclosure, setAssetsWithEnclosure ] = React.useState([]);
    
    React.useEffect(() => {
        if (model) {
            const { centersLngLat } = model;
            
            const newAssets = assets.map(asset => {
                const convexHulls = asset.geometry.coordinates.map(c => compute2DConvexHullCW(c[0]));
                const points = _.range(0, centersLngLat.length).filter(ind => {
                    for (let i = 0, len = convexHulls.length; i < len; ++i) {
                        if (checkPointInConvexPolyCW(convexHulls[i], [centersLngLat[ind].lng, centersLngLat[ind].lat])) {
                            return true;
                        }
                    }
                    
                    return false;
                })
                
                if (points.length === 0) {
                    const nearestGridIndex = model.queryNearestFloodGridIndex([asset.properties.X, asset.properties.Y]);
                    points.push(nearestGridIndex);
                }
                
                return {
                    ...asset,
                    enclosedGridPoints: points,
                }
            })
            
            setAssetsWithEnclosure(newAssets);
        }
        else {
            setAssetsWithEnclosure([]);
        }
    }, [assets, model]);
    
    return assetsWithEnclosure;
}

function useAssetsWithInundationState(assets, model) {
    const [ assetsWithInundationState, setAssetsWithInundationState ] = React.useState([]);
    
    React.useEffect(() => {
        if (model) {
            const { heights, baseHeights, } = model;
            
            const newAssets = assets.map(asset => {
                const threshold = asset.properties.Z_Impact;

                let status = 'SAFE';
                
                for (const ind of asset.enclosedGridPoints) {
                    for (let j = 0, len = heights.length; j < len; ++j) {
                        const height = heights[j][ind];

                        if (height > 0 && height + baseHeights[ind] > threshold) {
                            status = 'INUNDATED';
                            break;
                        }
                    }
                    
                    if (status === 'INUNDATED')
                        break;
                }
                
                return {
                    ...asset,
                    status,
                }
            })
            
            setAssetsWithInundationState(newAssets);
        }
        else {
            setAssetsWithInundationState([]);
        }
    }, [assets, model]);
    
    return assetsWithInundationState;
}

function useAssetsWithInundationPeriods(assets, model) {
    const [ assetsWithInundationPeriods, setAssetsWithInundationPeriods ] = React.useState([]);
    
    React.useEffect(() => {
        if (model) {

            const { baseHeights, heights } = model;
            
            const newAssets = assets.map(asset => {
                let periods = [];
                let periodStart = null;

                const maxIndices = _.map(_.range(heights.length), t => _.maxBy(asset.enclosedGridPoints, i => heights[t][i] + baseHeights[i]));
                const data = _.map(maxIndices, (index, i) => heights[i][index] + baseHeights[index]);
                
                for (let j = 0, len = heights.length; j < len; ++j) {
                    const height = data[j];
                    const threshold = asset.properties.Z_Impact;

                    if (height > baseHeights[maxIndices[j]] && height >= threshold) {
                        if (periodStart === null) {
                            if (j === 0) {
                                periodStart = j;
                            }
                            else {
                                if (data[j - 1] < threshold) {
                                    periodStart = (threshold - data[j - 1]) / (height - data[j - 1]) + j - 1;
                                }
                                else {
                                    periodStart = j;
                                }
                            }
                        }
                    }
                    else {
                        if (periodStart !== null) {
                            let periodEnd;

                            if (j === 0) {
                                periodEnd = j;
                            }
                            else {
                                if (data[j - 1] >= threshold) {
                                    periodEnd = (1 - (threshold - height) / (data[j - 1] - height)) + j - 1;
                                }
                                else {
                                    periodEnd = j;
                                }
                            }

                            periods.push([periodStart, periodEnd]);
                            periodStart = null;
                        }
                    }
                    
                    if (j === len - 1 && periodStart !== null) {
                        periods.push([periodStart, j]);
                        periodStart = null;
                    }
                }
                
                return {
                    ...asset,
                    inundationPeriods: periods,
                }
            })
            
            setAssetsWithInundationPeriods(newAssets);
        }
        else {
            setAssetsWithInundationPeriods([]);
        }
    }, [assets, model]);
    
    return assetsWithInundationPeriods;
}

export default useAssets;