import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Flex from 'App/styled/Flex';
import FlexFill from 'App/styled/FlexFill';

import { useSharedContext, setSharedContext } from 'Lib/hooks/useSharedContext';
import { logout } from 'App/actions';

import config from 'App/config';
import contexts from 'App/contexts';
import theme from 'App/themes';

// class NavbarOld extends StyledComponent({
//     background: {
//         height: 64,
//         backgroundColor: 'white',
//         borderBottom: '2px solid lightgrey',
//     },
//     logoText: {
//         // fontWeight: 'bold',
//         fontSize: 24,
//         marginLeft: 8,
//         marginRight: 32,
//         color: '#3C78B9',
//         // backgroundColor: '#F0F5F9',
//         padding: 13,
//     },
//     tabText: {
//         normal: {
//             marginRight: 12,
//             fontSize: 16,
//             color: '#0A161F',
//         },
//         selected: {
//             marginRight: 12,
//             fontSize: 16,
//             color: 'white',
//             backgroundColor: '#3C78B9'
//         }
//     },
// }) {
//     constructor(props) {
//         super(props);
        
//         this.onSelect = this.onSelect.bind(this);
//     }

//     onSelect(key, event) {
        
//         if (key === 'logout') {
//             localStorage.removeItem('fd_access_token');
//             window.location.reload();
//         }
//         else {
//             this.props.onTabSelected(key);
//         }
//     }

//     render() {
//         const styles = this.getStyles();
//         const {
//             tabInfo,
//             activeTab,
//         } = this.props;
        
//         console.log('rendered navbar')

//         return (
//             <Navbar expand='md' onSelect={this.onSelect} style={styles.background}>
//                     <Navbar.Brand style={styles.logoText}>Flood Dashboard</Navbar.Brand>
//                     <Navbar.Toggle aria-controls='basic-navbar-nav' />
//                     <Navbar.Collapse id='basic-navbar-nav' className='justify-content-end'>
//                         {/* <Nav.Item>
//                             <Nav.Link eventKey={'import'} style={{color: '#0A161F'}}>Import</Nav.Link>
//                         </Nav.Item> */}
//                         <div className="d-flex" style={{height: '48px', marginLeft: 12, marginRight: 16}}>
//                           <div className="vr"></div>
//                         </div>
//                         <Nav className='justify-content-end' variant='pills' defaultActiveKey={'display-map'} >
//                             {
//                                 tabInfo.map((d, i) => {
//                                     return (
//                                         <Nav.Item key={i}>
//                                             <Nav.Link eventKey={d.key} style={d.key === activeTab ? styles.tabText.selected : styles.tabText.normal}>{d.label}</Nav.Link>
//                                         </Nav.Item>
//                                     )
//                                 })
//                             }
//                             <Nav.Item>
//                                 <Nav.Link eventKey={'logout'} style={styles.tabText.normal}>Logout</Nav.Link>
//                             </Nav.Item>
//                         </Nav>
//                     </Navbar.Collapse>
//             </Navbar>
//         )
//     }
// }
// 

const NavbarFlex = styled(Flex)`
    height: ${config.NavbarHeight}px;
    background-color: white;
    align-items: center;
`

const Logo = styled.label`
    font-size: 32px;
    // font-weight: bold;
    margin-left: 16px;
    margin-right: 32px;
    color: ${props => props.color};
`

const NavbarItems = styled(FlexFill)`
    text-align: right;
`

const NavbarItem = styled.label`
    margin-right: 8px;
    font-size: 16px;
    color: ${props => props.selected ? 'white' : '#0A161F'};
    background-color: ${props => props.selected ? props.color : 'white'};
    padding: 8px 16px;
    border-radius: 8px;

    ${
        props => {
            return !props.disabled && !props.selected && `
                &:hover {
                    background-color: #D4E2F1;
                    cursor: pointer;
                }
            `
        }
    }

    transition: background-color;
    transition-duration: 0.3s;
`

const ThemeToggle = styled.div`
    vertical-align: bottom;
    color: white;
    padding: 4px 8px;
    font-size: 12px;

    &:hover {
        cursor: ${props => props.clickable ? 'pointer' : 'default'}
    }
`

const ThemeToggleLeft = styled(ThemeToggle)`
    padding-right: 16px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 24px 12px;
        border-color: transparent transparent #F8F8F8 transparent;
    }
`

const ThemeToggleRight = styled(ThemeToggle)`
    padding-left: 16px;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 24px 12px 0 0;
        border-color: #F8F8F8 transparent transparent transparent;
    }
`

function Navbar() {
    const userContext = useSharedContext(contexts.User);
    const navbarContext = useSharedContext(contexts.Navbar);

    const { simulationType,  } = useSharedContext(contexts.FloodEvent);

    const navigate = useNavigate();
    
    const tabs = userContext.user ? navbarContext.tabs : [];
    const currentTab = navbarContext.currentTab;
    
    // React.useEffect(() => {
    //     navigate(`/${currentTab.toLowerCase()}`)
    // }, [currentTab])
    
    function onTabClicked(tab) {
        switch (tab) {
            case 'Logout':
                logout()
                    .then(() => {
                        setTimeout(() => {
                            setSharedContext(contexts.User, {
                                user: null
                            });
                            navigate('/login')
                        }, 1000);
                    })
            
                break;
            default:
                // setSharedContext(contexts.Navbar, {
                //     currentTab: tab,
                // })
                break;
        }
    }
    
    return (
        <NavbarFlex>
            <Logo color={theme[simulationType].main}>Flood Dashboard</Logo>
            <div style={{position:'relative', marginTop: '4px'}}>
                <ThemeToggleLeft style={{ 
                    backgroundColor: simulationType === 'storm' ? theme['storm'].main : '#dddddd',
                    color: simulationType === 'storm' ? 'white' : 'black',
                }} clickable={simulationType !== 'storm'} onClick={() => {
                    if (simulationType !== 'storm') {
                        setSharedContext(contexts.FloodEvent, {
                            simulationType: 'storm',
                            eventName: 'current',
                        })
                        
                    }
                }} title="View models with stormsurge only.">Storm Tide</ThemeToggleLeft>
            </div>

            <div style={{position:'relative', marginTop: '4px'}}>
                <ThemeToggleRight style={{ 
                    backgroundColor: simulationType === 'storm_rain' ? theme['storm_rain'].main : '#dddddd',
                    color: simulationType === 'storm_rain' ? 'white' : 'black',
                }} clickable={simulationType !== 'storm_rain'} onClick={() => {
                    if (simulationType !== 'storm_rain') {
                        setSharedContext(contexts.FloodEvent, {
                            simulationType: 'storm_rain'
                        })
                    }
                }} title="View models with stormsurge and precipitation.">Storms Tide &amp; Precipitation</ThemeToggleRight>
            </div>
            <NavbarItems>
                {
                    tabs.map((tab, i) => {
                        return (
                            <NavbarItem key={i} selected={tab === currentTab} disabled={tab === '|'} onClick={onTabClicked.bind(null, tab)} color={theme[simulationType].main}> {tab} </NavbarItem>
                        )
                    })
                }
            </NavbarItems>
        </NavbarFlex>
    )
}

export default Navbar;