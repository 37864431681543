import React, { useEffect } from 'react';
import styled from 'styled-components';

import Flex from 'App/styled/Flex';
import FlexFill from 'App/styled/FlexFill';
import Absolute from 'App/styled/Absolute';

import Map from 'App/components/Map';
import AssetExplorer from 'App/components/AssetExplorer';
import IFCExplorer from 'App/components/IFCExplorer';
import Hydrograph from '../components/Hydrograph';
import ReportViewer from '../components/ReportViewer';
import LoadingScreen from '../components/LoadingScreen';
import MovableDivider from '../components/MovableDivider';

import config from 'App/config';

import { 
	contexts,
	useSharedContext,
	useSharedContextProvider,
	useSharedContextConsumer,
} from 'Lib/hooks/useSharedContext';


// class DashboardOld extends StyledComponent(app => {
// 	const {
// 		width,
// 		height,
// 	} = app.state;
	
// 	const appWidth = width;
// 	const appHeight = height - config.NavbarHeight;

// 	const orientation = width * 1.3 > height ? 'horizontal' : 'vertical';
	
// 	let mapWidth, mapHeight;
	
// 	switch(orientation) {
// 		case 'horizontal':
// 			mapWidth = appWidth / 2 - 2;
// 			mapHeight = appHeight;
// 			break;
// 		case 'vertical':
// 			mapWidth = appWidth;
// 			mapHeight = appHeight / 2 - 2;
// 			break;
// 	}

// 	return {
// 		app: {
// 			width: appWidth,
// 			height: appHeight,
// 			position: 'absolute',
// 			top: config.NavbarHeight,
// 			left: 0,
// 			overflow: 'hidden',
// 			// zIndex: (app.state.activeTab === 'display-map') ? 2 : 1,
// 			opacity: (app.state.activeTab === 'display-map') ? 1 : 0,
// 		},
// 		pdfViewer: {
// 			position: 'absolute',
// 			top: config.NavbarHeight,
// 			left: 0,
// 			width: width,
// 			height: height - config.NavbarHeight,
// 			// zIndex: (app.state.activeTab === 'display-map') ? 1 : 2,
// 			opacity: (app.state.activeTab === 'display-map') ? 0 : 1,
//             backgroundColor: 'white',
// 		},
// 		map: {
// 			display: 'inline-block',
// 			position: 'relative',
// 			width: mapWidth,
// 			height: mapHeight,
// 			fontSize: 12,
//             backgroundColor: 'white',
// 		},
// 		explorers: {
// 			display: 'inline-block',
// 			position: 'relative',
// 			width: mapWidth,
// 			height: mapHeight,
// 			fontSize: 12,
//             backgroundColor: 'white',
// 		},
// 		hierarchyExplorer: {
// 			width: mapWidth,
// 			height: mapHeight * 2 / 8,
// 			padding: 0,
// 			fontSize: 12,
// 			verticalAlign: 'top',
//             backgroundColor: 'white',
// 		},
// 		assetExplorer: {
// 			display: 'inline-block',
// 			width: mapWidth,
// 			height: mapHeight * 2 / 8,
// 			padding: 0,
// 			fontSize: 12,
// 			verticalAlign: 'top',
//             backgroundColor: 'white',
// 		},
// 		hydrograph: {
// 			display: 'inline-block',
// 			width: mapWidth,
// 			height: mapHeight * 4 / 8,
// 			verticalAlign: 'top',
//             backgroundColor: 'white',
// 		},
// 	}
// }) {
// 	constructor(props) {
// 		super(props);
		
// 		this.state = {
// 			width: window.innerWidth,
// 			height: window.innerHeight,

// 			selectedAsset: null,
// 			assets: [],

// 			hydrographData: [],
// 			activeTab: 'display-map',
// 			screenshotDataUrl: null,

// 			hierarchy: null,
// 			startTime: null,
// 			currentTime: null,
// 		}

// 		this.map = React.createRef();
// 		this.assetExplorer = React.createRef();
// 		this.hydrograph = React.createRef();

// 		this.onResize = this.onResize.bind(this);
// 		this.onIFCModelLoaded = this.onIFCModelLoaded.bind(this);
// 		this.augmentHierarchy = this.augmentHierarchy.bind(this);
// 		this.onToggleHierarchyVisibility = this.onToggleHierarchyVisibility.bind(this);
// 		this.onToggleHierarchyHighlight = this.onToggleHierarchyHighlight.bind(this);
// 		this.onAssetLoaded = this.onAssetLoaded.bind(this);
// 		this.getItemsProperties = this.getItemsProperties.bind(this);

// 		this.onAssetClicked = this.onAssetClicked.bind(this);
// 		this.onHydrographPointClick = this.onHydrographPointClick.bind(this);
// 		this.onTabSelected = this.onTabSelected.bind(this);
// 		this.onStartTimeChanged = this.onStartTimeChanged.bind(this);
// 		this.onTimeChanged = this.onTimeChanged.bind(this);
// 	}
	
// 	onResize(e) {
// 		this.setState({
// 			width: e.target.innerWidth,
// 			height: e.target.innerHeight,
// 		})
// 	}
	
// 	onIFCModelLoaded(ifcModel) {
// 		this.setState({
// 			hierarchy: this.augmentHierarchy(ifcModel.hierarchy),
// 		})
// 	}
	
// 	augmentHierarchy(hierarchy, parent=null) {
// 		const newHierarchy = {
// 			...hierarchy,
// 			collapsed: true,
// 			rendered: true,
// 			highlighted: false,
// 			parent: parent,
// 		}

// 		newHierarchy.children = hierarchy.children.map(c => this.augmentHierarchy(c, newHierarchy));
		
// 		return newHierarchy;
// 	}
	
// 	onAssetLoaded(assets) {
		
// 		if (this.state.selectedAsset) {
// 			this.setState({
// 				assets,
// 				hydrographData: this.map.current.queryNearestFloodGridData(this.state.selectedAsset),
// 			})
// 		}
// 		else {
// 			this.setState({
// 				assets,
// 			})
// 		}
		
// 		this.assetExplorer.current.forceUpdate();
// 		this.hydrograph.current.forceUpdate();
// 	}
	
// 	onToggleHierarchyVisibility(hierarchy) {
// 		let expressIDs = [];

// 		function getAllExpressIDs(hierarchy) {
// 			expressIDs.push(hierarchy.expressID);
			
// 			for (const c of hierarchy.children) {
// 				if (c.rendered === true) {
// 					getAllExpressIDs(c);
// 				}
// 			}
// 		}
		
// 		if (hierarchy.rendered) {
// 			let shouldRender = true;
// 			let current = hierarchy;
			
// 			while (current.parent !== null) {
// 				current = current.parent;

// 				if (!current.rendered) {
// 					shouldRender = false;
// 					break;
// 				}
// 			}
			
// 			if (shouldRender) {
// 				getAllExpressIDs(hierarchy, expressIDs);
// 				this.map.current.toggleElementVisibilities(expressIDs, hierarchy.rendered);
// 			}
// 		}
// 		else {
// 			getAllExpressIDs(hierarchy, expressIDs);
// 			this.map.current.toggleElementVisibilities(expressIDs, hierarchy.rendered);
// 		}
// 	}

// 	onToggleHierarchyHighlight(hierarchy) {
// 		let expressIDs = [];

// 		function getAllExpressIDs(hierarchy) {
// 			expressIDs.push(hierarchy.expressID);
			
// 			for (const c of hierarchy.children) {
// 				getAllExpressIDs(c);
// 			}
// 		}
		
// 		getAllExpressIDs(hierarchy, expressIDs);
// 		this.map.current.toggleElementHighlights(expressIDs, hierarchy.highlighted);
// 		this.map.current.toggle3DModel(true);
// 	}
	
	
// 	getItemsProperties(ids) {
// 		return this.map.current.getItemsProperties(ids);
// 	}
	
// 	componentDidMount() {
// 		window.addEventListener('resize', this.onResize);
// 	}
	
// 	componentWillUnmount() {
// 		window.removeEventListener('resize', this.onResize);
// 	}
	
// 	onAssetClicked(asset) {
// 		if (this.map.current === null) return;

// 		this.setState({
// 			selectedAsset: asset,
// 			hydrographData: this.map.current.queryNearestFloodGridData(asset),
// 		})

// 		this.map.current.focusAsset(asset);

// 		if (asset !== null) {
// 			this.map.current.toggle3DModel(false);
// 			this.map.current.toggleWireframeModel(true);
// 		}
// 	}
	
// 	onHydrographPointClick(id) {
// 		if (this.map.current === null) return;

// 		this.map.current.setFloodModelAnimationProgress(id);
// 	}
	
// 	onTabSelected(tab) {
// 		this.setState({
// 			activeTab: tab,
// 			screenshotDataUrl: null,
// 		}, () => {
// 			switch(tab) {
// 				case 'display-map':
// 					this.map.current.map.resize();
// 					break;
// 				case 'export-report':
// 					break;
// 			}
// 		})

// 		new Promise((resolve, reject) => {
// 			switch(tab) {
// 				case 'display-map':
// 					resolve(null);
// 					break;
// 				case 'export-report':
// 					this.map.current.screenshot()
// 						.then(image => {
// 							// return this.cropDataUrlImage(image.img, {
// 							// 	start: [0, 0],
// 							// 	size: image.size,
// 							// })
							
// 							resolve(image.img);
// 						})
// 						// .then(resolve)
// 					break;
// 			}
// 		})
// 			.then(dataUrl => {
// 				this.setState({
// 					screenshotDataUrl: dataUrl
// 				}, () => {
// 				})
// 			})
// 	}

// 	onStartTimeChanged(time) {
// 		this.setState({
// 			startTime: time,
// 		})
		
// 		if (this.hydrograph.current) {
// 			this.hydrograph.current.setStartTime(time);
// 		}
// 	}
	
// 	onTimeChanged(time) {
// 		this.setState({
// 			currentTime: time,
// 		})

// 		if (this.hydrograph.current) {
// 			this.hydrograph.current.setCurrentTime(time);
// 		}
// 	}

// 	cropDataUrlImage(dataUrl, options) {
// 		const canvas = document.createElement('canvas');

// 		canvas.width = options.size[0];
// 		canvas.height = options.size[1];
		
// 		const ctx = canvas.getContext('2d');

// 		return new Promise((resolve, reject) => {
// 			const img = new Image();
// 			img.src = dataUrl;
// 			img.onload = () => {
// 				ctx.drawImage(img, options.start[0], options.start[1], options.size[0], options.size[1]);
// 				ctx.save();
				
// 				// resolve(canvas.toDataURL());
// 				resolve(dataUrl);
// 			}
// 		})
// 	}
	
// 	render() {
// 		const navbarHeight = 64;
// 		const {
// 			width, 
// 			height,
// 			activeTab,
// 			assets,
// 			selectedAsset,
// 			hydrographData,
// 			screenshotDataUrl,
// 			hierarchy,
// 			startTime,
// 			currentTime,
// 		} = this.state;
		
// 		const layoutId = (width * 1.3 > height) ? 3 : 1;
// 		const styles = this.getStyles();
		
// 		return (
// 			<span>

// 			</span>
// 		)
		
// 		// return (
// 		// 	<div className="Dashboard">
// 		// 		<LoadingScreen id="loading-screen" />

// 		// 		<Header 
// 		// 			onTabSelected={this.onTabSelected}
// 		// 			tabInfo={tabInfo}
// 		// 			activeTab={activeTab}
// 		// 			/>
			
// 		// 		<div style={styles.app}>
// 		// 			<Container fluid='true'>
// 		// 				<Row sm={layoutId} className='p-0 m-0'>
// 		// 					{/* <Col className='p-0 m-0' sm='auto'>
// 		// 						<div style={styles.map}>
// 		// 							<Map
// 		// 								ref={this.map} 
// 		// 								onIFCModelLoaded={this.onIFCModelLoaded}
// 		// 								onAssetLoaded={this.onAssetLoaded}
// 		// 								onAssetClicked={this.onAssetClicked}
// 		// 								selectedAsset={selectedAsset}
// 		// 								currentTime={currentTime}
// 		// 								startTime={startTime}
// 		// 								onStartTimeChanged={this.onStartTimeChanged}
// 		// 								onTimeChanged={this.onTimeChanged}
// 		// 								/>
// 		// 						</div>
// 		// 					</Col>
// 		// 					{
// 		// 						layoutId === 1
// 		// 							? 	<Row className='p-0 m-0' sm='auto'>
// 		// 									<MovableDivider type={'horizontal'} size={4} />
// 		// 								</Row>
// 		// 							: 	<Col className='p-0 m-0' sm='auto'>
// 		// 									<MovableDivider type={'vertical'} size={4} />
// 		// 								</Col>
// 		// 					}
// 		// 					<Col className='p-0 m-0'>
// 		// 						<div style={styles.explorers}>
// 		// 							<div style={styles.hierarchyExplorer}>
// 		// 								<IFCExplorer
// 		// 									hierarchy={hierarchy}
// 		// 									onToggleHierarchyVisibility={this.onToggleHierarchyVisibility}
// 		// 									onToggleHierarchyHighlight={this.onToggleHierarchyHighlight}
// 		// 									getItemsProperties={this.getItemsProperties}
// 		// 									/>
// 		// 							</div>

// 		// 							<div style={styles.assetExplorer}>
// 		// 								<AssetExplorer 
// 		// 									ref={this.assetExplorer} 
// 		// 									assets={assets} 
// 		// 									selectedAsset={selectedAsset}
// 		// 									onAssetClicked={this.onAssetClicked}
// 		// 									/>
// 		// 							</div>
									
// 		// 							<div style={styles.hydrograph}>
// 		// 								<Hydrograph 
// 		// 									ref={this.hydrograph}
// 		// 									selectedAsset={selectedAsset} 
// 		// 									data={hydrographData} 
// 		// 									onPointClick={this.onHydrographPointClick}
// 		// 									/>
// 		// 							</div>
// 		// 						</div> */}
// 		// 						{/* <Row className='p-0 m-0'>
// 		// 						</Row>
// 		// 						<Row className='p-0 m-0'>
// 		// 						</Row>
// 		// 						<Row className='justify-content-center m-0 p-0'>
// 		// 						</Row> */}
// 		// 					{/* </Col> */}
// 		// 				</Row>
// 		// 			</Container>
// 		// 		</div>

// 		// 		{
// 		// 			(activeTab === 'export-report') &&
// 		// 			<div style={styles.pdfViewer}>
// 		// 				<ReportViewer 
// 		// 					screenshotDataUrl={screenshotDataUrl}
// 		// 					/>
// 		// 			</div>
// 		// 		}
// 		// 	</div>
// 		// );
// 	}
// }

const MapContainer = styled.div`
	flex: 0.5;
	height: 100%;
	background-color: black;
	border-right: 1px solid black;
	min-width: 0;
	min-height: 0;
`

const ExplorerContainer = styled(Flex)`
	flex: 0.5;
	flex-direction: column;
	background-color: blue;
	min-width: 0;
	min-height: 0;
`

const IFCExplorerContainer = styled(Flex)`
	flex: 0.3;
	background-color: purple;
`

const AssetExplorerContainer = styled(Flex)`
	flex: 0.3;
	background-color: green;
`

const HydrographContainer = styled(Flex)`
	flex: 0.4;
	flex-direction: column;
	background-color: red;
	max-width: calc(${props => props.flexDirection === 'column' ? 100 : 50}vw);
	max-height: calc(calc(100vh - 64px) * 0.4);
`

function Dashboard(props) {
	const ref = React.useRef();
	const [flexDirection, setFlexDirection] = React.useState('row');
	
	function onWindowResize() {
		if (ref.current) {
			const elem = ref.current;
			const width = elem.offsetWidth;
			const height = elem.offsetHeight;
			
			setFlexDirection((width * 1.2 < height) ? 'column' : 'row');
		}
	}
	
	React.useEffect(() => {
		onWindowResize();
		window.addEventListener('resize', onWindowResize);
		return () => window.removeEventListener('resize', onWindowResize);
	}, [])

	return (
		<Flex>
			<Absolute>
				<LoadingScreen />
			</Absolute>

			<Flex ref={ref} style={{ flexDirection, }}>
				<MapContainer>
					<Map />
				</MapContainer>
				<ExplorerContainer>
					<IFCExplorerContainer>
						<IFCExplorer />
					</IFCExplorerContainer>
					<AssetExplorerContainer>
						<AssetExplorer />
					</AssetExplorerContainer>
					<HydrographContainer flexDirection={flexDirection}>
						<Hydrograph />
					</HydrographContainer>
				</ExplorerContainer>
			</Flex>
		</Flex>
	)
}

export default Dashboard;