import React, { Component, Fragment } from 'react'
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';

import FlexFill from 'App/styled/FlexFill';
import Flex from 'App/styled/Flex';

import { useSharedContext, setSharedContext } from 'Lib/hooks/useSharedContext';

import contexts from 'App/contexts';
import config from 'App/config';
import theme from 'App/themes';

const UpTriangle = () => <Fragment>&#9652;</Fragment>;
const DownTriangle = () => <Fragment>&#9662;</Fragment>;

// const GoodStatusMark = () => (
//     <Fragment>
//         <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
//         <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
//     </Fragment>
// )

// const BadStatusMark = () => (
//     <Fragment>
//         <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
//         <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
//     </Fragment>
// )

const TitleBar = styled.div`
    background-color: ${props => props.color};
    color: white;
    width: 100%;
    height: 20px;
    font-size: 12px;
    text-align: center;
`

const TableContainer = styled(FlexFill)`
    min-height: 0;
    background-color: white;
    overflow: auto;
    align-items: center;
`

const Table = styled.table`
    width: 100%;
`

const Thead = styled.thead`
    position: sticky;
    top: 0;
    left: 0;
    background-color: #BDD0DB;
    z-index: 2;
    font-size: 12px;
`

const Tr = styled.tr`
    position: relative;
    border-bottom: 1px solid #D8E3E9;
    background-color: ${props => props.isHighlighted ? '#AAF683' : `transparent`};
    font-size: 12px;
`

const Td = styled.td`
    vertical-align: middle;
    text-align: left;
    border-left: 1px solid #D8E3E9;
    padding: 0px 2px;

    // &:first-child {
    //     border-left: 0px;
    //     width: 1%;
    // }
`

const Th = styled.th`
    text-align: left;
    border-left: 1px solid ${props => props.color};
    padding: 0px 4px;

    &:first-child {
        border-left: 0px;
    }

`

function AssetExplorer({}) {
    const { assets, selected } = useSharedContext(contexts.Assets);
    const { startTime, simulationType } = useSharedContext(contexts.FloodEvent);

    const [state, setState] = React.useReducer((state, newState) => ({ ...state, ...newState }), {
        orderIndex: 2,
        orderType: 'asc',
        sortedAssets: [],
    });
    
    const { orderType, orderIndex, sortedAssets } = state;
    
    function onHeaderClicked(e) {
        const clickedIndex = parseInt(e.currentTarget.getAttribute('value'));
        
        if (clickedIndex == orderIndex) {
            setState({
                orderType: (orderType === 'desc') ? 'asc' : 'desc',
            })
        }
        else {
            setState({
                orderIndex: clickedIndex,
            })
        }
    }
    
    function onAssetClicked(asset) {
        setSharedContext(contexts.Assets, {
            selected: asset,
        })
    }
    
    React.useEffect(() => {
        function sortAssets(assets) {
            let sortVals;
            
            switch(orderIndex) {
                case 0:
                    sortVals = assets.map(x => x.properties.Name);
                    break;
                case 1:
                    sortVals = assets.map(x => x.properties.Z_Impact);
                    break;
                case 2:
                    sortVals = assets.map(x => x.inundationPeriods.length === 0 ? 9999999 : x.inundationPeriods[0][0])
            }

            let sortedAssets = _.sortBy(_.zip(assets, sortVals), x => x[1]).map(x => x[0]);
            
            if (orderType === 'desc') {
                sortedAssets = _.reverse(sortedAssets);
            }
            
            return sortedAssets;
        }

        setState({
            sortedAssets: sortAssets(assets),
        })
    }, [assets, orderIndex, orderType])
    
    const createTimestamp = elapsed => startTime ? moment(startTime, config.TimeFormat).add(elapsed, 'hour').format(config.TimeFormat) : '';
    
    return React.useMemo(() => (
        <Flex style={{ flexDirection: 'column', }}>
            <TitleBar color={theme[simulationType].main}>Assets</TitleBar>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            {
                                ['Asset Name', 'Threshold (Feet)', 'Inundation Periods'].map((c, i) => {
                                    return (
                                        <Th onClick={onHeaderClicked} key={i} value={i} color={theme[simulationType].main}>
                                            {c} 
                                            {i === orderIndex && ((orderType === 'asc') ? <UpTriangle /> : <DownTriangle />)}
                                        </Th>
                                    )
                                })
                            }
                        </Tr>
                    </Thead>
                    <tbody>
                        {
                            sortedAssets.map((asset, i) => {
                                return (
                                    <Tr key={i} onClick={() => onAssetClicked(asset)} isHighlighted={selected && asset.id === selected.id}>
                                        <Td>
                                            { asset.properties.Name }
                                        </Td>
                                        <Td>
                                            { asset.properties.Z_Impact }
                                        </Td>
                                        <Td className='text-nowrap'>
                                            {
                                                asset.inundationPeriods.map((p, i) => {
                                                    return <div key={i}>{`${createTimestamp(p[0])} - ${createTimestamp(p[1])}`}</div>
                                                })
                                            }
                                        </Td>
                                    </Tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </TableContainer>
        </Flex>
    ), [ startTime, sortedAssets, selected, simulationType ]);
}

export default AssetExplorer;