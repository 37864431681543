import React from 'react';
import _ from 'lodash';

import Animation from 'Lib/animation';

import { useSharedContext, setSharedContext } from 'Lib/hooks/useSharedContext';
import contexts from 'App/contexts';


function FloodAnimation({ map, model }) {
	const { animation, isPaused, progress, duration }= useSharedContext(contexts.FloodAnimation);

	React.useEffect(() => {
		if (animation) {
			if (isPaused) {
				animation.pause();
			}
			else {
				animation.play();
			}
		}
	}, [animation, isPaused]);
	
	React.useEffect(() => {
		if (animation) {
			animation.setFrame(progress);
		}
	}, [animation, progress]);

	React.useEffect(() => {
		if (model) {
			setSharedContext(contexts.LoadingScreen, {
				active: true,
				message: 'Initializing Flood Animation',
			})

			const frameCount = model.heights.length;
			
			let prevFrame = -1, prevTime = -1;
			
			const animation = new Animation();
			
			animation.create({
				duration,
				frameCount,
				onUpdate: (time) => {
					if (time != prevTime) {
						prevTime = time;

						const frame = parseInt(Math.floor(time)) % frameCount;
						
						model.setTime(time - frame);
						
						if (prevFrame != frame) {
							model.setFrame(frame);
							prevFrame = frame;
						}

						if (map)
							map.triggerRepaint();
						
						setSharedContext(contexts.FloodAnimation, {
							progress: time,
						})
					}
				}
			})

			setSharedContext(contexts.FloodAnimation, {
				maxProgress: frameCount - 1,
				animation,
			});

			setSharedContext(contexts.LoadingScreen, {
				active: false,
			})

			
			// setSharedContext(contexts.FloodAnimation, {
			// 	isPaused: true,
			// 	progress: 0,
			// });

			return () => {
				animation.dispose();
				setSharedContext(contexts.FloodAnimation, {
					animation: null,
					maxProgress: 1,
					progress: 0,
				})
			}
		}
	}, [map, model, duration])
	
	React.useEffect(() => {
	}, [])
	
	return null;
}

export default FloodAnimation;