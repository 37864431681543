import React from 'react'
import ReactDOM from 'react-dom';
import styled, { ThemeProvider } from 'styled-components';

import useMapbox from './useMapbox';

import ModelController from 'App/components/ModelController';
import Models from './Models';
import Assets from './Assets';

import Flex from 'App/styled/Flex';
import Absolute from 'App/styled/Absolute';

import { useSharedContext, useSetSharedContext, setSharedContext } from 'Lib/hooks/useSharedContext';
import contexts from 'App/contexts';

import { Raycaster, Vector2 } from 'three';
import _, { forEach } from 'lodash';
import proj4 from 'proj4';

import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import { Form, ButtonGroup,  ToggleButton } from 'react-bootstrap';

import config from "App/config";

import AssetInfo from '../AssetInfo';
import { withLoadingScreen } from '../LoadingScreen';

import * as THREE from "three";
import axios from 'axios';

import Custom3DLayer from '../../mapbox/custom-3d-layer';
import MapboxIFCPicker from '../../mapbox/mapbox-ifc-picker';
import { transformGeometryToMapboxCoordinateSystem } from "../../mapbox/helpers";

import * as hdf5 from 'jsfive'

import Panoramic from './Panoramic';
import mapboxgl from 'mapbox-gl';

const MapContainer = styled(Flex)`
	position: relative;
	background-color: white;
`

const LegendContainer = styled.div`
	position: absolute;
	bottom: 84px;
	right: 8px;
	width: fit-content;
	height: fit-content;
	z-index: 10;
	background-color: white;
	padding: 6px;
	border-radius: 6px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
`

const LegendGroup = styled.div`
	display: inline-block;
	height: fit-content;
	width: fit-content;
	vertical-align: middle;
	text-align: right;
`

const LegendLabel = styled.label`
	display: block;
	color: black;
	font-size: 12px;
	line-height: 20px;
`

const LegendColor = styled.div`
	width: 32px;
	height: 20px;
	background-color: ${props => props.color};
`

function Legend(props) {
	const { colorScale } = useSharedContext(contexts.FloodEvent);
	const { model } = useSharedContext(contexts.FloodModel);
	const { map } = useSharedContext(contexts.Map);
	
	React.useEffect(() => {
		if (model) {
			const settings = {
				blue: {
					hue: {
						max: 233 / 360.0,
						min: 185 / 360.0,
					},
					sat: {
						max: 100 / 100.0,
						min: 100 / 100.0,
					},
					val: {
						max: 70 / 100.0,
						min: 100 / 100.0,
					}
				},
				muddy: {
					hue: {
						max: 29 / 360.0,
						min: 30 / 360.0,
					},
					sat: {
						max: 62.6 / 100.0,
						min: 37.2 / 100.0,
					},
					val: {
						max: 54.5 / 100.0,
						min: 90.7 / 100.0,
					}
				}
			}
			
			const currentSetting = settings[colorScale];
			
			model.model.material.uniforms.maxHue.value = currentSetting.hue.max;
			model.model.material.uniforms.minHue.value = currentSetting.hue.min;
			model.model.material.uniforms.maxSat.value = currentSetting.sat.max;
			model.model.material.uniforms.minSat.value = currentSetting.sat.min;
			model.model.material.uniforms.maxVal.value = currentSetting.val.max;
			model.model.material.uniforms.minVal.value = currentSetting.val.min;
			
			if (map) {
				map.triggerRepaint();
			}
		}
	}, [model, colorScale, map])

	return React.useMemo(() => (
		<LegendContainer>
			<LegendGroup>
				<LegendLabel>Max</LegendLabel>
				<LegendLabel>2.0</LegendLabel>
				<LegendLabel>1.5</LegendLabel>
				<LegendLabel>1.0</LegendLabel>
				<LegendLabel>0.5</LegendLabel>
				<LegendLabel>0.01</LegendLabel>
			</LegendGroup>
			&nbsp;
		
			{
				colorScale === 'blue' 
					? 	<LegendGroup>
							<LegendColor color={'rgba(0, 21, 179, 0.6)'} />
							<LegendColor color={'rgba(0, 63, 198, 0.6)'} />
							<LegendColor color={'rgba(0, 112, 217, 0.6)'} />
							<LegendColor color={'rgba(0, 169, 236, 0.6)'} />
							<LegendColor color={'rgba(0, 234, 255, 0.6)'} />
						</LegendGroup>
					: 	<LegendGroup>
							<LegendColor color={'rgba(139, 94, 52, 0.6)'} />
							<LegendColor color={'rgba(164, 113, 72, 0.6)'} />
							<LegendColor color={'rgba(188, 138, 95, 0.6)'} />
							<LegendColor color={'rgba(212, 162, 118, 0.6)'} />
							<LegendColor color={'rgba(231, 188, 145, 0.6)'} />
						</LegendGroup>

			}
		</LegendContainer>
	), [colorScale]);
}

function Popup() {
	const { selected } = useSharedContext(contexts.Assets);
	
	return (
		<Absolute style={{ marginTop: 100, height: 'calc(100% - 200px)' }}>
				{ selected && <AssetInfo asset={selected} />}
		</Absolute>
	)
}

const ProgressBar = styled.input.attrs({
    type: 'range',
})`
    flex-grow: 1;
    vertical-align: middle;
`

function Map(props) {
	const { map, customLayer, mode, } = useSharedContext(contexts.Map);

    const mapRef = React.useRef();
    const panoramaRef = React.useRef();
	
	React.useEffect(() => {
		if (customLayer) {
			customLayer.paused = mode != 'map-view';
		}
	}, [customLayer, mode]);

	useMapbox(mapRef, config.MapboxMapOptions, () => {
		return map => {
			const customLayer = new Custom3DLayer(config.MapboxCustomLayerOptions);

			map.addLayer(customLayer);
			setSharedContext(contexts.Map, { map, customLayer, });
			
			const markerInfos = [
				{
					location: 'parkinglot_008',
					coord: [623039.680392, 693163.112149],
				},
				{
					location: 'conn_007',
					coord: [622789.688961, 693197.062977],
				},
				{
					location: 'backoflightrail_001',
					coord: [622588.096915, 692673.01321],
				},
			]
			
			const markers = markerInfos.map(info => {
				const popupDiv = document.createElement('div');
				const popup = new mapboxgl.Popup()
					.setDOMContent(popupDiv)
				
				ReactDOM.render(
					<button style={{}} className='btn btn-light' onClick={() => {
						setSharedContext(contexts.Map, {
							mode: 'panorama-view'
						})
						setSharedContext(contexts.Panoramic, {
							location: info.location, 
						})

						setSharedContext(contexts.Assets, {
							selected: null,
						})
					}}>
						Panoramic View
					</button>,
					popupDiv,
				)

				const marker = new mapboxgl.Marker()
					.setLngLat(proj4('ESRI:102711:Feet', 'WGS84').forward(info.coord))
					.setPopup(popup)
					.addTo(map);
				
				return marker;
			})
			
			
			
			return () => {
				markers.forEach(marker => marker.remove());
				map.removeLayer(customLayer.id);
				setSharedContext(contexts.Map, { map: null, customLayer: null, })
			}
		}
	});
	
	return React.useMemo(() => (
		<MapContainer>
			<Panoramic mapRef={panoramaRef} />
			<Models map={map} customLayer={customLayer} />
			
			<Assets map={map} />
		
			<Absolute>
				<ModelController />
			</Absolute>

			<Popup /> 

			<Absolute ref={mapRef} style={{ zIndex: (mode === 'map-view') ? 2 : 1, backgroundColor: 'white'}}/>
			<Absolute style={{ zIndex: (mode === 'panorama-view') ? 2 : 1}}>
				<div style={{ position: 'absolute', top: 112, right: 12, width:'fit-content', height:'fit-content', zIndex: 10, backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: 6, borderRadius: 6,}} onClick={() => {
					setSharedContext(contexts.Map, {
						mode: 'map-view'
					})
				}}>
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
					  <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
					  <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
					</svg>
				</div>
				<Absolute ref={panoramaRef} />
			</Absolute>

			<Legend />
		</MapContainer>
	), [ map, customLayer, mode,]);
}
export default Map;