import React from 'react';

import TerminalModel from './TerminalModel';
import FloodModel from './FloodModel';

import { setSharedContext } from 'Lib/hooks/useSharedContext';
import contexts from 'App/contexts';
import config from 'App/config';

function Models({ map, customLayer }) {
	return React.useMemo(() => {
		return (
			<React.Fragment>
				<TerminalModel map={map} customLayer={customLayer} />
				<FloodModel map={map} customLayer={customLayer} />
			</React.Fragment>
		)
	}, [map, customLayer])
}

export default Models;