import * as THREE from "three";
import { IFCLoader } from 'web-ifc-three/IFCLoader';
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from 'three-mesh-bvh';
import _ from 'lodash';

import { compute2DConvexHullCW, checkPointInConvexPolyCW, } from "Lib/geom";
import GeoreferencedMeshWrapper from "Lib/georeferenced-mesh-wrapper";

import Model from "./model";

import { IFCROOF, IFCSLAB } from 'web-ifc';

class IFCModel extends Model {
    constructor() {
        super();
        
        this.ifcLoader = new IFCLoader();
        this.ifcManager = this.ifcLoader.ifcManager;
        
        this.ifcManager.setWasmPath("../../") // configured for React
        this.ifcManager.applyWebIfcConfig({
            USE_FAST_BOOLS: false,
        })
        this.ifcManager.useWebWorkers('true', './IFCWorker.js');

        this.ifcManager.setupThreeMeshBVH(computeBoundsTree, disposeBoundsTree, acceleratedRaycast);
        
        this.getMesh = this.getMesh.bind(this);
        this.getWireframe = this.getWireframe.bind(this);
        this.toggle3DModelVisibility = this.toggle3DModelVisibility.bind(this);
        this.toggleWireframeVisibility = this.toggleWireframeVisibility.bind(this);
        this.toggleElementVisibilities = this.toggleElementVisibilities.bind(this);
        this.toggleElementHighlights = this.toggleElementHighlights.bind(this);
        this.editFeature = this.editFeature.bind(this);
        this.convertToVertexColorMode = this.convertToVertexColorMode.bind(this);
        this.convertToDoubleSidedRendering = this.convertToDoubleSidedRendering.bind(this);
        this.generateWireframe = this.generateWireframe.bind(this);
        this.computeFeatureCoordinateMap = this.computeFeatureCoordinateMap.bind(this);
        this.load = this.load.bind(this);
    }
    
    dispose() {
        this.ifcManager.dispose();
    }
    
    getMesh() {
        return this.model;
    }
    
    getWireframe() {
        return this.wireframe;
    }
    
    toggle3DModelVisibility(visible) {
        this.model.geometry.setDrawRange(0, visible ? Infinity : 0);
    }

    toggleWireframeVisibility(visible) {
        this.wireframe.geometry.setDrawRange(0, visible ? Infinity : 0);
    }
    
    toggleElementVisibilities(ids, rendered) {
        const positions = this.model.geometry.attributes.position;
        const positionsArray = positions.array;
        const initial = this.initialPositions;
        
        if (!rendered) {
            this.editFeature(ids, (start, end) => {
                const zeros = [0, 0, 0];

                for (let i = start * 3, len = end * 3; i < len; i += 3) {
                    positionsArray.set(zeros, i);
                }
            })
        }
        else {
            this.editFeature(ids, (start, end) => {
                for (let i = start * 3, len = end * 3; i < len; i += 3) {
                    positionsArray.set([initial[i], initial[i+1], initial[i+2]], i);
                }
            })
        }
        
        this.wireframe.geometry.attributes.position.array.set(positionsArray);
        
        positions.needsUpdate = true;
        this.wireframe.geometry.attributes.position.needsUpdate = true;
    }
    
    toggleElementHighlights(ids, highlight) {
        const colors = this.model.geometry.attributes.color;
        const colorsArray = colors.array;
        const initial = this.initialColors;
        
        if (highlight) {
            this.editFeature(ids, (start, end) => {
                for (let i = start * 3, len = end * 3; i < len; i += 3) {
                    colorsArray.set([1 - initial[i], 1, 1 - initial[i+2]], i);
                }
            })
        }
        else {
            this.editFeature(ids, (start, end) => {
                for (let i = start * 3, len = end * 3; i < len; i += 3) {
                    colorsArray.set([initial[i], initial[i+1], initial[i+2]], i);
                }
            })
        }
        
        colors.needsUpdate = true;
    }
    
    editFeature(ids, editor) {
        const map = this.featureCoordinateMap;
        ids.forEach(id => {
            let ranges = map[id];
            
            if (ranges) {
                for (const range of ranges) {
                    editor(range[0], range[1]);
                }
            }
        })
    }
    
    computeFeatureCoordinateMap(model) {
        const map = {};
        
        const expressID = model.geometry.attributes.expressID.array;
        
        for (let i = 0, len = expressID.length; i < len;) {
            let start = i;
            let end = i;
            
            const id = expressID[start];
            
            while (end < len && expressID[end] === id) ++end;
            
            let mappings = map[id];
            
            if (mappings === undefined) {
                mappings = [];
                map[id] = mappings;
            }

            mappings.push([start, end])

            i = end;
        }
        
        return map;
    }
    
    /**
     * 
     * @param {[Object]} options 
     * @param {[String]} options.src IFC model source
     * @param {[String]} options.proj projection system of the model
     * @param {[String]} options.onProgress on download progress callback
     * @returns {[Promise]} promise for model loading
     */
    load(options) {
        options = {
            onProgress: () => null,
            wireframeColor: 0x000000,
            wireframeOpacity: 0.1,
            ...options,
        }

        return this.ifcLoader.loadAsync(options.src, options.onProgress)
            .then(model => {
                this.model = GeoreferencedMeshWrapper(model, options.proj);
                this.featureCoordinateMap = this.computeFeatureCoordinateMap(model);

                this.convertToVertexColorMode();
                this.convertToDoubleSidedRendering();

                this.initialColors = this.model.geometry.attributes.color.array.slice();

                return model.getSpatialStructure();
            })
            .then(hrc => {
                this.hierarchy = hrc;
            })
            .then(() => {
                const targetStoreys = {
                    [175]: true,
                    [193]: true,
                    [199]: true,
                }
                
                const targetTypes = {
                    'IFCROOF': true,
                    'IFCSLAB': true,
                }
                
                const filterItems = {
                    [1983]: true,
                }

                const buildingStoreys = this.hierarchy.children[0].children[0].children;
                
                const roofLikeItems = [];
                
                function getAllItemsInGroup(group) {
                    for (const item of group.children) {
                        if (item.children.length === 0) {
                            if (targetTypes[item.type] && !filterItems[item.expressID]) {
                                roofLikeItems.push(item)
                            }
                        }
                        else {
                            getAllItemsInGroup(item);
                        }
                    }
                }
                
                for (const storey of buildingStoreys) {
                    if (targetStoreys[storey.expressID]) {
                        getAllItemsInGroup(storey);
                    }
                }
                
                const itemGeometries = roofLikeItems.map(item => {
                    const vertexRanges = this.featureCoordinateMap[item.expressID];
                    
                    let geometries = []
                    
                    for (const [start, end] of vertexRanges) {
                        const geom2d = _.chunk(this.model.geometry.attributes.position.array.slice(start * 3, end * 3), 3)
                        geometries.push(compute2DConvexHullCW(geom2d.map(x => [x[0], -x[2]])))
                    }

                    return geometries;
                })
                
                this.roofGeometries = itemGeometries;
                
            })
            .then(() => {
                this.model.project('Mapbox');
                this.initialPositions = this.model.geometry.attributes.position.array.slice();
                this.wireframe = this.generateWireframe(this.model, options);
                
                return this;
            })
    }
}

export default IFCModel;