import axios from "axios";

import config from "./config"

function fdFetch(route, options={}) {
    const {
        headers,
        ...otherOpts
    } = options;

    const token = localStorage.getItem('fd_access_token');

    return axios.request(config.ServerUrl + route, {
        headers: {
            'x-access-token': token,
            ...headers,
        },
        ...otherOpts,
    })
        .catch(e => {
            console.log(e)
            // window.location.reload();
        })
}

function fdFetchEventData(eventName, simType, dataType, options) {
    switch (dataType) {
        case 'info':
            return fdFetch(config.Routes.getEvents + "/" + simType + "/" + eventName + "/" + dataType, options)
                .then(res => res.data)
        case 'grid':
        case 'heights':
        case 'mesh':
            return fdFetch(config.Routes.getEvents + "/" + simType + "/" + eventName + "/" + dataType, {
                responseType: 'arraybuffer',
                ...options
            })
                .then(res => res.data)
        default:
            return new Promise((_, reject) => {
                reject({
                    msg: 'Invalid event data type.',
                })
            })
    }
}

function authenticate(options) {
    return fdFetch(config.Routes.authenticate, options)
        .then(res => res.data)
}

function login(username, password) {
    return fdFetch(config.Routes.login, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        data: JSON.stringify({ username, password }),
    })
        .then(res => res.data)
        .then(json => {
            if (json.token) {
                localStorage.setItem('fd_access_token', json.token);
            }
            
            return json;
        })
}

function logout() {
    return new Promise((resolve, reject) => {
        localStorage.removeItem('fd_access_token');
        resolve(null);
    })
}

function getEvents(simType, options) {
    return fdFetch(config.Routes.getEvents + "/" + simType, options)
        .then(res => res.data)
}

function getEventInfo(eventName, simType, options) {
    return fdFetchEventData(eventName, simType, 'info', options);
}

function getEventGrid(eventName, simType, options) {
    return fdFetchEventData(eventName, simType, 'grid', options);
}

function getEventHeights(eventName, simType, options) {
    return fdFetchEventData(eventName, simType, 'heights', options);
}

function getEventMesh(eventName, simType, options) {
    return fdFetchEventData(eventName, simType, 'mesh', options);
}

function getAssets(options) {
    return fdFetch(config.Routes.getAssets, options)
        .then(res => res.data)
}

function getPanoramicInfo(location, options) {
    return fdFetch(config.Routes.getPanoramics+ "/" + location + "/info", {
        ...options,
    })
        .then(res => {
            return res.data
        })
}

function getPanoramicImage(location, options) {
    return fdFetch(config.Routes.getPanoramics+ "/" + location + "/image", {
        responseType: 'arraybuffer',
        ...options,
    })
        .then(res => {
            return res.data
        })
}

function getPanoramicDepth(location, options) {
    return fdFetch(config.Routes.getPanoramics+ "/" + location + "/depth", {
        responseType: 'arraybuffer',
        ...options,
    })
        .then(res => {
            return res.data
        })
}

export {
    fdFetch,
    authenticate,
    login,
    logout,
    getEvents,
    getEventInfo,
    getEventGrid,
    getEventHeights,
    getEventMesh,
    getAssets,
    getPanoramicInfo,
    getPanoramicImage,
    getPanoramicDepth,
}