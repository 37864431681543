import React from 'react';

function useChainedPromise(p) {
    const promise = React.useRef(p ? p : Promise.resolve());
    return ({
        then: function(cb) {
            promise.current = promise.current.then(cb);
            return this;
        },
        catch: function(cb) {
            promise.current = promise.current.catch(cb);
            return this;
        },
        finally: function(cb) {
            promise.current = promise.current.finally(cb);
            return this;
        }
    });
}

export default useChainedPromise;