import _ from 'lodash';

function compute2DConvexHullCW(poly) {
    const vertCount = poly.length - 1;
    
    const startInd = _.maxBy(_.range(0, vertCount), i => {
        return poly[i][1];
    });
    
    const startPoint = poly[startInd];
    
    const ranks = poly.slice(0, vertCount).map((p, i) => {
        if (i === startInd) {
            return [2, i];
        }
        else {
            const x = p[0] - startPoint[0];
            const y = p[1] - startPoint[1];
            
            return [- x / Math.sqrt(x * x + y * y), i];
        }
    })
    
    const visitOrder = _.sortBy(ranks, rank => rank[0]).map(x => x[1]);
    
    const convexHull = [
        startPoint,
        poly[visitOrder[0]],
    ];
    
    for (let i = 1, len = visitOrder.length; i < len; ++i) {
        const r = poly[visitOrder[i]];
        
        while (convexHull.length >= 2) {
            const p = convexHull[convexHull.length - 2];
            const q = convexHull[convexHull.length - 1];
            
            if ((q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1]) <= 0) {
                convexHull.pop();
            }
            else {
                break;
            }
        }
        
        convexHull.push(r);
    }

    return convexHull;
}

function compute2DConvexHullCCW(poly) {
    const vertCount = poly.length - 1;
    
    const startInd = _.maxBy(_.range(0, vertCount), i => {
        return poly[i][1];
    });
    
    const startPoint = poly[startInd];
    
    const ranks = poly.slice(0, vertCount).map((p, i) => {
        if (i === startInd) {
            return [2, i];
        }
        else {
            const x = p[0] - startPoint[0];
            const y = p[1] - startPoint[1];
            
            return [x / Math.sqrt(x * x + y * y), i];
        }
    })
    
    
    const visitOrder = _.sortBy(ranks, rank => rank[0]).map(x => x[1]);
    
    const convexHull = [
        startPoint,
        poly[visitOrder[0]],
    ];
    
    for (let i = 1, len = visitOrder.length; i < len; ++i) {
        const r = poly[visitOrder[i]];
        
        while (convexHull.length >= 2) {
            const p = convexHull[convexHull.length - 2];
            const q = convexHull[convexHull.length - 1];
            
            if ((q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1]) >= 0) {
                convexHull.pop();
            }
            else {
                break;
            }
        }
        
        convexHull.push(r);
    }
    
    return convexHull;
}

function checkPointInConvexPolyCW(poly, point) {
    const r = point;

    for (let i = 0, len = poly.length - 1; i < len; ++i) {
        const p = poly[i];
        const q = poly[i + 1];
        
        if ((q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1]) < 0) {

            return false;
        }
    }

    return true;
}

export {
    compute2DConvexHullCW,
    compute2DConvexHullCCW,
    checkPointInConvexPolyCW,
}