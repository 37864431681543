import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import Layout from 'App/pages/Layout';
import Login from 'App/pages/Login';
import Dashboard from 'App/pages/Dashboard';
import Export from 'App/pages/Export';
import { contexts, withSharedContexts } from 'App/contexts';

import { setSharedContext } from 'Lib/hooks/useSharedContext';
import { authenticate } from 'App/actions';

import 'mapbox-gl/dist/mapbox-gl.css'
import 'App/index.css';

function withRouter(Component) {
	return props => (
		<BrowserRouter>
			<Component {...props} />
		</BrowserRouter>
	)
}

function App(props) {
	const [loading, setLoading] = React.useState(true);
	const navigate = useNavigate();

	React.useEffect(() => {
		authenticate()
			.then(json => {
				if (json.error) {
					navigate('/login');
				}
				else {
					setSharedContext(contexts.User, {
						user: json.user,
					})
					navigate('/dashboard');
				}

				setLoading(false);
			})
	}, []);
	
	if (loading) return null;

	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route path="dashboard" element={<Dashboard />} />
				<Route path="export" element={<Export />} />
				<Route path="login" element={<Login />} />
				<Route path="*" element={null} />
			</Route>
		</Routes>
	)
}


export default withSharedContexts(withRouter(App));