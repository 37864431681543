import React from 'react';
import _ from 'lodash';

import { useSharedContext, useSetSharedContext } from 'Lib/hooks/useSharedContext';
import contexts from 'App/contexts';

import useAssetManager from './useAssetManager';
import { setSharedContext } from 'src/Lib/hooks/useSharedContext';

function AssetManager({ map, model }) {
    const { selected, assets } = useSharedContext(contexts.Assets);

    const assetManager = useAssetManager(map, assets, {
		layerId: 'hoboken-asset-layer',
		source: 'hoboken-assets',
		sourceLayer: 'HobokenBaseAssets_20211102-3m4v9w',
    })
    
    React.useEffect(() => {
        if (assetManager && model) {
            assetManager.onAssetClicked(asset => {
                setSharedContext(contexts.Assets, {
                    selected: asset,
                })
            })
        }
    }, [assetManager, model])

	React.useEffect(() => {
		function queryHydrographData(asset) {
			const { heights, baseHeights, } = model;
			const lowestGridIndex = _.minBy(asset.enclosedGridPoints, i => baseHeights[i]);

			let lowestGridElevation = baseHeights[lowestGridIndex];
			lowestGridElevation = Math.min(lowestGridElevation, asset.properties.Z_Base);
			
			const data = _.map(_.range(heights.length), t => lowestGridElevation + heights[t][lowestGridIndex]);
			
			return data;
		}

		if (assetManager) {
			if (selected !== null) {
				setSharedContext(contexts.TerminalModel, {
					show3DModel: false,
					showWireframe: true,
				})
				
				if (model) {
					setSharedContext(contexts.Hydrograph, {
						data: queryHydrographData(selected),
					})
				}
			}
			
			assetManager.focusAsset(selected);
		}
	}, [assetManager, selected, model])

    return null;
}

export default AssetManager;