import React from 'react';
import moment from 'moment';
import { File as HDF5File } from 'jsfive';

import { useSharedContext, setSharedContext } from 'Lib/hooks/useSharedContext';

import { FloodModel, } from 'App/mapbox/3d-models/flood-model';

import contexts from 'App/contexts';
import config from 'App/config';
import { getEventInfo, getEventGrid, getEventHeights, getEventMesh } from 'App/actions';

const startTimePath = '/Plan Data//Plan Information';
const startTimeAttr = 'Simulation Start Time';
const precipitationExcessPath = '/Results/Unsteady/Summary/Volume Accounting/Volume Accounting 2D/Perimeter 1';
const precipitationExcessAttr = 'Precip Excess (inches)';
const waterElevationPath = "/Results/Unsteady/Output/Output Blocks/Base Output/Unsteady Time Series/2D Flow Areas/Perimeter 1/Water Surface";
const baseElevationPath = "/Geometry/2D Flow Areas/Perimeter 1/Cells Minimum Elevation";
const centerPath = '/Geometry/2D Flow Areas/Perimeter 1/Cells Center Coordinate';

function readHeightDataFromHDF5(file) {
    const reader = new FileReader();
    
    return new Promise(resolve => {
        reader.onloadend = e => {
            const barr = e.target.result;
            const hdf5 = new HDF5File(barr, file.name);

            let startTime = hdf5.get(startTimePath).attrs[startTimeAttr];
            startTime = moment(startTime, 'DDMMMYYYY HH:mm:ss').format("YYYY-MM-DD HH:mm:ss");

            const precipitationExcess = hdf5.get(precipitationExcessPath).attrs[precipitationExcessAttr];
            
            const centerData = hdf5.get(centerPath);

            const baseHeightData = hdf5.get(baseElevationPath);
            const heightData = hdf5.get(waterElevationPath);
            
            const centers = Float32Array.from(centerData.value);
            const heights = Float32Array.from(heightData.value);
            const baseHeights = Float32Array.from(baseHeightData.value);
            
            const interval = hdf5.get(startTimePath).attrs['Base Output Interval'];
            let skipIndexCount;
            
            switch (interval) {
                case '1MIN':
                    skipIndexCount = 60
                    break;
                case '5MIN':
                    skipIndexCount = 12 
                    break;
                case '10MIN':
                    skipIndexCount = 6 
                    break;
                case '15MIN':
                    skipIndexCount = 4
                    break;
                default:
                    skipIndexCount = 1
                    break;
            }
            
            const newCenters = new Float32Array(baseHeights.length * 3);
            const frameCount = (Math.floor(heightData.shape[0] / skipIndexCount) + 1);
            const newHeights = new Float32Array(frameCount * baseHeights.length);

            for (let i = 0, len = baseHeights.length; i < len; ++i) {
                newCenters[i * 3] = centers[i * 2];
                newCenters[i * 3 + 1] = centers[i * 2 + 1];
                newCenters[i * 3 + 2] = baseHeights[i];
            }
            
            for (let i = 0; i < frameCount; ++i) {
                const offset1 = i * baseHeights.length;
                const offset2 = offset1 * skipIndexCount;

                for (let j = 0, len_j = baseHeights.length; j < len_j; ++j) {
                    newHeights[offset1 + j] = heights[offset2 + j] - baseHeights[j];
                }
            }
            
            getEventMesh('current', 'storm_rain')
                .then(meshBuffer => {
                    resolve([{
                        startTime,
                        precipitationExcess
                    }, newCenters, newHeights, meshBuffer]);
                })
        }
        
        reader.readAsArrayBuffer(file);
    })
}

function FloodEvent({ mesh }) {
    const { model: terminalModel, } = useSharedContext(contexts.TerminalModel);
    const { uploadFile, eventName, simulationType } = useSharedContext(contexts.FloodEvent);
    
    React.useEffect(() => {
        if (terminalModel) {
            setSharedContext(contexts.LoadingScreen, { 
                active: true,
                message: 'Loading Flood Event...',
            })
            
            let getEventData = null;
            
            switch (eventName) {
                case 'upload':
                    getEventData = readHeightDataFromHDF5(uploadFile);
                    break;
                default:
                    getEventData = Promise.all([
                        getEventInfo(eventName, simulationType),
                        getEventGrid(eventName, simulationType),
                        getEventHeights(eventName, simulationType, {
                            onDownloadProgress: e => {
                                setSharedContext(contexts.LoadingScreen, {
                                    progress: e.loaded / e.total,
                                })
                            }
                        }),
                        getEventMesh(eventName, simulationType),
                    ])
                        .then(res => {
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    resolve(res);
                                }, 1000);
                            })
                        })
                    break;
            }
            
            getEventData
                .then(([info, gridBuffer, heightBuffer, meshBuffer]) => {
                    const model = new FloodModel();
                    model.loadEvent(
                        config.FloodModelOptions,
                        info,
                        new Float32Array(gridBuffer), 
                        new Uint32Array(meshBuffer),
                        new Float32Array(heightBuffer), 
                        terminalModel.roofGeometries,
                    );
                    
                    setSharedContext(contexts.FloodModel, { model, });
                    setSharedContext(contexts.FloodEvent, { startTime: info.startTime, });
                    setSharedContext(contexts.LoadingScreen, { active: false, });
                })
        }
        
    }, [ terminalModel, eventName, simulationType,])

    return null;
}

export default FloodEvent;