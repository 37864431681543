import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import Flex from 'App/styled/Flex';
import FlexFill from 'App/styled/FlexFill';
import Navbar from '../components/Navbar';

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background-image: url("https://www.stvinc.com/sites/default/files/2017-03/Hoboken%20Terminal%20and%20Yards.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

function Layout(props) {
    return (
        <Background>
            <Flex style={{ flexDirection: 'column' }}>
                <Navbar />
                <FlexFill>
                    <Outlet />
                </FlexFill>
            </Flex>
        </Background>
    )
}

export default Layout;