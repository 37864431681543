import React, { Component, useEffect } from 'react'
import styled from 'styled-components';
import _ from 'lodash';

import Flex from 'App/styled/Flex';

import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, TimeScale } from 'chart.js';
import { Chart, Line } from 'react-chartjs-2';
import moment from 'moment';

import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment';

import { useSharedContext, setSharedContext } from 'Lib/hooks/useSharedContext';
import contexts from 'App/contexts';
import theme from 'App/themes';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, TimeScale, annotationPlugin);

const TitleBar = styled.div`
    background-color: ${props => props.color};
    color: white;
    width: 100%;
    height: 20px;
    font-size: 12px;
    text-align: center;
`

function useChartData(data) {
    return React.useMemo(() => ({
        labels: _.range(0, data.length),
        datasets: [
            {
                label: 'Water Elevation (US Feet)',
                data,
                borderColor: '#0077B6',
                borderWidth: 1,
                backgroundColor: 'white',
                pointRadius: 3,
                pointHoverRadius: 3,
                pointHoverBackgroundColor: "red"
            }
        ]
    }), [data])
}

function useChartOptions(chartRef, asset, data, startTime) {
    const { progress } = useSharedContext(contexts.FloodAnimation);
    const progressRef = React.useRef(0);

    React.useEffect(() => {
        progressRef.current = progress;
        if (chartRef.current) {
            chartRef.current.update();
        }
    }, [chartRef, progress]);

    return React.useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 12,
            },
        },
        animation: {
            duration: 0
        },
        hover: {
            animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        plugins: {
            annotation: {
                animations: {
                    numbers: {
                        properties: [],
                        type: 'number'
                    }
                },
                annotations: {
                    impactLevel: {
                        type: 'line',
                        scaleID: 'y',
                        value: asset && asset.properties.Z_Impact,
                        borderColor: 'rgba(255, 0, 0, 0.5)',
                        borderWidth: 1,
                        drawTime: 'afterDatasetsDraw',
                    },
                    impactArea: {
                        type: 'box',
                        scaleID: 'y',
                        xMin: moment(startTime, 'YYYY-MM-DD HH:mm:ss'),
                        xMax: moment(startTime, 'YYYY-MM-DD HH:mm:ss').add(data.length, 'hour'),
                        yMin: asset && asset.properties.Z_Impact,
                        yMax: 100,
                        borderColor: 'rgba(0, 0, 0, 0)',
                        drawTime: 'afterDatasetsDraw',
                        backgroundColor: 'rgba(255, 0, 0, 0.03)'
                    },
                    hoverLine: {
                        type: 'line',
                        mode: 'vertical',
                        scaleID: 'x',
                        value: (ctx, opts) => {
                            if (ctx.type === 'annotation') {
                                const chart = ctx.chart;
                                const xScale = chart.scales.x;
                                const hours = progressRef.current;
                                return (hours / data.length) * (xScale.max - xScale.min) + xScale.min;
                            }
                            else {
                                return 0;
                            }
                            
                        },
                        borderColor: 'rgba(0, 255, 0, 0.5)',
                        borderWidth: 3,
                        drawTime: 'afterDatasetsDraw',
                    }
                },
            },
            legend: null,
        },
        scales: {
            x: {
                type: 'time',
                title: {
                    display: true,
                    text: 'Time',
                    color: '#4E6373',
                    font: {
                        weight: 'normal',
                        size: 12,
                    },
                },
                time: {
                    unit: 'hour',
                    unitStepSize: 1,
                    parser: (i) => {
                        return moment(startTime, 'YYYY-MM-DD HH:mm:ss').add(i, 'hour');
                    },
                    displayFormats: {
                        minute: 'YYYY-MM-DD HH:00:00',
                        hour: 'YYYY-MM-DD HH:00:00'
                    }
                },
                ticks: {
                    callback: (v, i) => {
                        if (i % 24 === 0) {
                            return moment(v, 'YYYY-MM-DD HH').format('YYYY-MM-DD HH:00');
                        }
                        else {
                            return moment(v, 'YYYY-MM-DD HH').format('HH:00');
                        }
                    }
                },
                min: 0,
                max: data.length,
            },
            y: {
                title: {
                    display: true,
                    text: 'Water Level (USFeet)',
                    color: '#4E6373',
                    font: {
                        weight: 'normal',
                        size: 12,
                    },
                }
            }
        },
        onClick: (e, elems) => {
            if (elems.length > 0) {
                setSharedContext(contexts.FloodAnimation, {
                    progress: elems[0].index,
                    isPaused: true,
                })
            }
        },
    }), [asset, startTime, data, progressRef])
}

function Graph({ asset }) {
    const { data } = useSharedContext(contexts.Hydrograph);
    const { startTime } = useSharedContext(contexts.FloodEvent);
    const chartRef = React.useRef(null);
    
    const chartData = useChartData(data);
    const chartOptions = useChartOptions(chartRef, asset, data, startTime || '1970-01-01 00:00:00');
    
    return React.useMemo(() => {
        return (
            <div style={{ backgroundColor: 'white', width: '100%', height: 'calc(100% - 20px)' }}>
                <Line ref={chartRef} data={chartData} options={chartOptions} />
            </div>
        )
    }, [chartData, chartOptions])
}

function Hydrograph({}) {
    const { simulationType } = useSharedContext(contexts.FloodEvent);
    const { selected } = useSharedContext(contexts.Assets);
    const [ savedAsset, setSavedAsset ] = React.useState(null);

    React.useEffect(() => {
        if (selected) {
            setSavedAsset(selected);
        }
    }, [selected])

    return React.useMemo(() => (
        <div style={{ width: '100%', height: '100%' }}>
            <TitleBar color={theme[simulationType].main}>Hydrograph {savedAsset ? `(${savedAsset.properties.Name})` : ''}</TitleBar>
            <Graph asset={savedAsset} />
        </div>
    ), [savedAsset, simulationType])
}

export default Hydrograph;