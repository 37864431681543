import React, { Children } from 'react';
import styled from 'styled-components';

import Flex from 'App/styled/Flex';
import FlexFill from 'App/styled/FlexFill';

import { useSharedContext, setSharedContext } from 'Lib/hooks/useSharedContext';
import contexts from 'App/contexts';
import theme from 'App/themes';

const Dot = props => <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" {...props} />;
const Plus = props => <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" {...props} />;
const Minus = props => <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" {...props} />;

const TitleBar = styled.div`
    background-color: ${props => props.color};
    color: white;
    width: 100%;
    height: 20px;
    font-size: 12px;
    text-align: center;
`

const TableContainer = styled(FlexFill)`
    min-height: 0;
    background-color: white;
    overflow: auto;
    align-items: center;
`

const Table = styled.table`
    width: 100%;
`

const Thead = styled.thead`
    position: sticky;
    top: 0;
    left: 0;
    background-color: #BDD0DB;
    z-index: 2;
    font-size: 12px;
`

const Tr = styled.tr`
    position: relative;
    border-bottom: 1px solid #D8E3E9;
    background-color: ${props => props.isHighlighted ? '#AAF683' : `transparent`};
    font-size: 12px;
`

const Td = styled.td`
    vertical-align: middle;
    text-align: left;
    border-left: 1px solid #D8E3E9;
    padding: 0px 2px;

    &:first-child {
        border-left: 0px;
        width: 1%;
    }

    &:nth-child(2) {
        width: 1%;
    }
`

const Th = styled.th`
    text-align: left;
    border-left: 1px solid ${props => props.color};
    padding: 0px 4px;

    &:first-child {
        border-left: 0px;
    }

`

const Checkbox = styled.input.attrs({
    type: 'checkbox',
})`
    margin: 0px 4px;
    vertical-align: middle;

    &:hover {
        cursor: ${props => props.disabled ? 'default' : 'pointer'};
    }
`

const DropDownToggle = styled.svg.attrs(props => ({
    xmlns: "http://www.w3.org/2000/svg",
    width: 16,
    height: 16,
    fill: (props.isLeaf ? 'black' : (props.isCollapsed ? "#3C78B9" : "#EF1A28")),
    viewBox: '0 0 16 16',
    children: (props.isLeaf ? <Dot /> : (props.isCollapsed ? <Plus /> : <Minus />)),
}))`

`

function getIDs(hierarchyRef, pred=true,results=[]) {
    const { childrenRefs, hierarchy, } = hierarchyRef.current;
    const { expressID, } = hierarchy;

    results.push(expressID);
    
    for (const ref of childrenRefs) {
        if (pred === true || pred(ref)) {
            getIDs(ref, pred, results);
        }
    }
    
    return results;
}

function HierarchyItemRow({ hierarchyRef, shouldCollapse, }) {
    const { map, model, hierarchy, state, setState, childrenRefs, indent } = hierarchyRef.current;
    const { isRendered, isCollapsed, isHighlighted, properties, } = state;
    const { type } = hierarchy;
    
    if (!properties || shouldCollapse) return null;
    
    function toggleVisibility() {
        if (!isRendered) {
            let shouldRender = true;
            let currentRef = hierarchyRef;
            
            while (currentRef.current.parentRef) {
                currentRef = currentRef.current.parentRef;
                
                if (!currentRef.current.state.isRendered) {
                    shouldRender = false;
                    break;
                }
            }
            
            if (shouldRender) {
                const ids = getIDs(hierarchyRef, ref => ref.current.state.isRendered);
                model.toggleElementVisibilities(ids, !isRendered);
                map.triggerRepaint();
            }
        }
        else {
            const ids = getIDs(hierarchyRef, ref => ref.current.state.isRendered);
            model.toggleElementVisibilities(ids, !isRendered);
            map.triggerRepaint();
        }
        
        setState({
            isRendered: !isRendered,
        })
    }
    
    function toggleExpand() {
        setState({
            isCollapsed: !isCollapsed,
        })
    }
    
    function toggleHighlight() {
        const ids = getIDs(hierarchyRef);
        
        model.toggleElementHighlights(ids, !isHighlighted);
        map.triggerRepaint();
        
        setSharedContext(contexts.TerminalModel, {
            show3DModel: true,
        })

        setState({
            isHighlighted: !isHighlighted,
        })
    }
    
    return (
        <Tr isHighlighted={isHighlighted}>
            <Td onClick={toggleVisibility}>
                <Checkbox 
                    clicktype='checkbox'
                    checked={isRendered}
                    readOnly={true}
                />
            </Td>
            <Td style={{ paddingLeft: indent * 4 + 2, }} onClick={toggleExpand} >
                <DropDownToggle 
                    clicktype='dropdown'
                    isLeaf={childrenRefs.length === 0} 
                    isCollapsed={isCollapsed} 
                />
            </Td>
            <Td onClick={toggleHighlight}>
                {type}
            </Td>
            <Td onClick={toggleHighlight}>
                {properties.Name.value}
            </Td>
        </Tr>
    )
}

function HierarchyItem({ hierarchyRef, isParentCollapsed }) {
    const { map, model, hierarchy, indent, } = hierarchyRef.current;
    const { expressID, children, } = hierarchy;

    const [state, setState] = React.useReducer((state, newState) => ({ ...state, ...newState }), {
        isCollapsed: true,
        isRendered: true,
        isHighlighted: false,
        properties: null,
    });

    const childrenRefs = React.useMemo(() => {
        return children.map(c => {
            const ref = React.createRef()

            ref.current = { 
                parentRef: hierarchyRef, 
                hierarchy: c,
                indent: indent + 1,
                model,
                map,
            } 
            
            return ref;
        })
    }, [children])
    
    const { isCollapsed, } = state;

    Object.assign(hierarchyRef.current, {
        childrenRefs,
        state,
        setState,
    })

    React.useEffect(() => {
        model.model.getItemProperties(expressID)
            .then(properties => {
                setState({
                    properties,
                })
            })
    }, [model]);
    
    const shouldCollapse = isParentCollapsed || isCollapsed;
    
    const childrenComponents = React.useMemo(() => {
        return childrenRefs.map((ref, i) => 
            <HierarchyItem 
                key={i} 
                hierarchyRef={ref} 
                isParentCollapsed={shouldCollapse}
            />
        )
    }, [childrenRefs, shouldCollapse]);
    
    return (
        <React.Fragment>
            <HierarchyItemRow hierarchyRef={hierarchyRef} shouldCollapse={isParentCollapsed}/>
            { childrenComponents }
        </React.Fragment>
    );
};

function TableBody({}) {
    const { model } = useSharedContext(contexts.TerminalModel);
    const { map } = useSharedContext(contexts.Map);
    
    if (!model) return null;
    
    const ref = React.createRef();
    
    ref.current = {
        model,
        map,
        parentRef: null,
        hierarchy: model.hierarchy,
        indent: 0,
    }
    
    return (
        <tbody>
            <HierarchyItem hierarchyRef={ref} />
        </tbody>
    )
}

function IFCExplorer(props) {
    const { simulationType } = useSharedContext(contexts.FloodEvent);

    return (
        <Flex style={{ flexDirection: 'column', }}>
            <TitleBar color={theme[simulationType].main}>IFC Structure</TitleBar>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th color={theme[simulationType].main}></Th>
                            <Th color={theme[simulationType].main}></Th>
                            <Th color={theme[simulationType].main}>Type</Th>
                            <Th color={theme[simulationType].main}>Name</Th>
                        </Tr>
                    </Thead>
                    <TableBody />
                </Table>

            </TableContainer>
        </Flex>
    )
}

export default IFCExplorer;
