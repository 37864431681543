import React from 'react';

import { useSharedContext, setSharedContext, } from 'Lib/hooks/useSharedContext';
import contexts from 'App/contexts';
import { getEvents, getFloodModelMesh } from 'App/actions'

import FloodAnimation from './FloodAnimation';
import FloodEvent from './FloodEvent';

function FloodModel({ map, customLayer }) {
	const { model, exaggeration, } = useSharedContext(contexts.FloodModel);
	const { simulationType } = useSharedContext(contexts.FloodEvent);
	
	React.useEffect(() => {
		getEvents(simulationType)
			.then(events => {
				setSharedContext(contexts.FloodEvent, {
					events,
				})
			})
		
	}, [simulationType])
	
	React.useEffect(() => {
		if (customLayer && model) {
			
			if (model.wireframe) {
				customLayer.addModel(model.wireframe);
			}

			customLayer.addModel(model.model);
			
			if (map) map.triggerRepaint();
			
			return () => {
				if (model.wireframe) {
					customLayer.removeModel(model.wireframe);
				}

				customLayer.removeModel(model.model);
			}
		}
	}, [ map, customLayer, model, ])
	
	React.useEffect(() => {
		if (model) {
			model.setExaggeration(exaggeration);
			
			if (map) {
				map.triggerRepaint();
			}
		}
	}, [ map, model, exaggeration, ]);
	
	return React.useMemo(() => {
		return (
			<React.Fragment>
				<FloodAnimation map={map} model={model} />
				<FloodEvent map={map} model={model} />
			</React.Fragment>
		)
	}, [ map, model, ]);
}

export default FloodModel;