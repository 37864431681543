import React, { Component } from 'react';

import {
    PDFViewer,
    Document,
    Page,
    View,
    Text,
    Image,
    StyleSheet,
} from '@react-pdf/renderer';

const pdfStyles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    section: {
        marginTop: 8,
        marginBottom: 8,
        marginLeft: 24,
        marginRight: 24,
        // padding: 10,
        flexGrow: 1,
        textAlign: 'center',
        justifyContent: 'center',
    },
    view: {
        marginTop: 16,
        border: '1px solid black',
    },
    image: {
        // width: 1920,
        // height: 1080,
        objectFit: 'cover',
    },
    title: {
        fontWeight: 'bold',
    },
    text: {
        fontWeight: 'normal',
        fontSize: '10px',
    },
    body: {
        marginTop: 8,
        textAlign: 'left',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
    },
    description: {
        // display: 'inline-block',
        padding: '2px 6px 2px 6px',
        width: '48%',
    },
    legend: {
        padding: '2px 6px 2px 6px',
        // display: 'inline-block',
        width: '20%',
        height: '100%',
        backgroundColor: 'lightblue'
    },
    rule: {
        // marginTop: 3,
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
    },
    ruleText: {
        fontWeight: 'normal',
        fontSize: '8px',
        width: 20,
    },
    box: {
        width: 24,
        height: 8,
        marginRight: 12,
    }
})

class ReportViewer extends Component {
    constructor(props) {
        super(props);
        
        this.makeDocument = this.makeDocument.bind(this);
    }
    
    makeDocument() {
        if (this.props.screenshotDataUrl === null) {
            return null;
        }
        else {
            return (
                <Document>
                    <Page size='A5' orientation='landscape' style={pdfStyles.page}>
                        <View style={pdfStyles.section}>
                            <Text style={pdfStyles.title}>Test Report (Template)</Text>
                            <View style={pdfStyles.view}>
                                <Image src={this.props.screenshotDataUrl} style={pdfStyles.image}></Image>
                            </View>
                            <View style={pdfStyles.body}>
                                <View style={pdfStyles.description}>
                                    <Text style={pdfStyles.text}>This file was generated on xx/xx/xx at xx:xx</Text>
                                    <Text style={pdfStyles.text}>FileName: Report1.pdf</Text>
                                    <Text style={pdfStyles.text}>Forecast Time: xx/xx/xx xx:xx</Text>
                                    <Text style={pdfStyles.text}>Model Name: Hoboken</Text>
                                    <Text style={pdfStyles.text}>Hours After Forecast Time to Max Water Level: xx</Text>
                                    <Text style={pdfStyles.text}>Max Water Level: xx</Text>
                                    <Text style={pdfStyles.text}>Percent Affected: xx%</Text>
                                </View>
                                <View style={pdfStyles.legend}>
                                    <Text style={pdfStyles.text}>Flood Depth Contour</Text>
                                    <View style={pdfStyles.rule}>
                                        <View style={{ backgroundColor: 'rgb(215, 215, 255)', ...pdfStyles.box }}></View>
                                        <Text style={{ ...pdfStyles.ruleText }}>0 - 1</Text>
                                    </View>
                                    <View style={pdfStyles.rule}>
                                        <View style={{ backgroundColor: 'rgb(175, 175, 255)', ...pdfStyles.box }}></View>
                                        <Text style={{ ...pdfStyles.ruleText }}>1 - 2</Text>
                                    </View>
                                    <View style={pdfStyles.rule}>
                                        <View style={{ backgroundColor: 'rgb(175, 175, 255)', ...pdfStyles.box }}></View>
                                        <Text style={{ ...pdfStyles.ruleText }}>2 - 3</Text>
                                    </View>
                                    <View style={pdfStyles.rule}>
                                        <View style={{ backgroundColor: 'rgb(135, 135, 255)', ...pdfStyles.box }}></View>
                                        <Text style={{ ...pdfStyles.ruleText }}>3 - 4</Text>
                                    </View>
                                    <View style={pdfStyles.rule}>
                                        <View style={{ backgroundColor: 'rgb(95, 95, 255)', ...pdfStyles.box }}></View>
                                        <Text style={{ ...pdfStyles.ruleText }}>4 - 5</Text>
                                    </View>
                                    <View style={pdfStyles.rule}>
                                        <View style={{ backgroundColor: 'rgb(55, 55, 255)', ...pdfStyles.box }}></View>
                                        <Text style={{ ...pdfStyles.ruleText }}>5+ </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Page>
                </Document>
            )
        }

    }
    
    render() {
        const document = this.makeDocument();

        return(
            <PDFViewer width='100%' height='100%'>
                {document}
             </PDFViewer>
        )
    }
}

export default ReportViewer;