import React from 'react';
import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useSharedContext, setSharedContext } from 'Lib/hooks/useSharedContext';

import HVCenteredFlex from 'App/styled/HVCenteredFlex';

import contexts from 'App/contexts';
import { login } from 'App/actions';

const FormContainer = styled.span`
    // background-color: rgba(0, 0, 0, 0.85);
    background-color: rgba(255, 255, 255, 0.95);
    color: black;
    padding: 36px 54px;
    border-radius: 16px;
`

const FormField = React.forwardRef((props, ref) => {
    const {
        id,
        label,
        ...others
    } = props;
    
    return (
        <Form.Group className="mb-3" controlId={id}>
            <Form.Label className='text-primary'>{label}</Form.Label>
            <Form.Control ref={ref} {...others} />
        </Form.Group>
    )
})

function FormText(props) {
    return (
        <Form.Group className="mb-3">
            <Form.Text className={props.className}>
                {props.value}
            </Form.Text>
        </Form.Group>
    )
}

function Login(props) {
    const usernameRef = React.useRef();
    const passwordRef = React.useRef();
    
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    
    const navigate = useNavigate();
    
    const loginCallback = React.useCallback(() => {
        setLoading(true);
        
        login(usernameRef.current.value, passwordRef.current.value)
            .then(json => {
                setTimeout(() => {
                    if (json.error) {
                        setError(json.error);
                        setLoading(false);
                    }
                    else {
                            setSharedContext(contexts.User, {
                                user: json.user,
                                token: json.token,
                            })
                            navigate('/dashboard');
                    }
                }, 1000);
            })
    }, []);
    
    return (
        <HVCenteredFlex>
            <FormContainer>
                <FormField
                    ref={usernameRef}
                    id='landing-email' 
                    label='Username' 
                    type="text" 
                    placeholder="Enter Username" 
                    disabled={loading}
                />
                <FormField
                    ref={passwordRef}
                    id='landing-password' 
                    label='Password' 
                    type="password" 
                    placeholder="Enter Password" 
                    disabled={loading}
                />
                <FormText className='text-danger' value={error}/>
                <Form.Group className="mt-4">
                    <Button 
                        variant="primary" 
                        type="submit" 
                        disabled={loading}
                        onClick={loginCallback}
                    >Login</Button>
                </Form.Group>
            </FormContainer>
        </HVCenteredFlex>
    )
}

export default Login;
