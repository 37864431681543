let ServerUrl;

if (process.env.NODE_ENV === 'development') {
    ServerUrl = 'http://localhost:3001';
}
else {
    ServerUrl = window.location.origin;
}

const TimeFormat = "YYYY-MM-DD HH:mm:ss";
const FeetToMeters = 0.3048;
const NavbarHeight = 64;

const MapboxMapOptions = {
	// origin: [-74.032292, 40.735357],
	center: [-74.02760384575308, 40.73497897582878],
	pitch: 0,
	zoom: 16.5,
	accessToken: "pk.eyJ1IjoieWFvYzE5OTYiLCJhIjoiY2t3aXJrZzE3MWJidjJ1cDltN3BwMjZodSJ9.zmJ-NIR77BbcezqCZE4cvQ",
	style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
    preserveDrawingBuffer: true,
	antialias: true,
    trackResize: true,
};

const MapboxCustomLayerOptions = {
    id: 'mb-custom-layer',
    origin: MapboxMapOptions.center,
}

const Projection = "ESRI:102711:Feet";

const TerminalModelOptions = {
    origin: MapboxMapOptions.center,
    src: ServerUrl + "/files/hoboken/HobokenTerminalFinal.ifc",
    proj: "ESRI:102711:Feet",
}

const FloodModelOptions = {
    meshSrc: "/files/hoboken/flood_model.mesh",
    proj: "ESRI:102711:Feet",
}

const Routes = {
    authenticate: "/authenticate",
    login: "/login",
    getEvents: "/events",
    getAssets: "/files/hoboken/assets.json",
    getPanoramics: "/panoramics"
}

export default {
    TimeFormat,
    ServerUrl,
    FeetToMeters,
    NavbarHeight,
    MapboxMapOptions,
    MapboxCustomLayerOptions,
    TerminalModelOptions,
    FloodModelOptions,
    Routes,
}