import React, { Component } from 'react';

// class MovableDivider extends StyledComponent(divider => {
//     const { type, size } = divider.props;

//     return {
//         div: {
//             display: 'inline-block',
//             width: (type === 'horizontal') ? '100%' : size,
//             height: (type === 'vertical') ? '100%' : size,
//             backgroundColor: 'black',
//             verticalAlign: 'top',
//         }
//     }
// }) {
//     constructor(props) {
//         super(props)
//     }

//     render() {
//         const styles = this.getStyles();

//         return (
//             <div style={styles.div}></div>
//         )
//     }
// }

// MovableDivider.defaultProps = {
//     type: 'vertical',
//     size: 4,
// }
// 

function MovableDivider() {
    return null;
}

export default MovableDivider;