import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import proj4 from "proj4";
import _ from 'lodash';


function GeoreferencedMeshWrapper(mesh, proj) {
    let origin = [0, 0];
    let projection = proj;

    function projectToMapboxMercator() {
        const projCoordToWGS84 = proj4(projection, "WGS84");
        const toMeter = proj4(projection).oProj.to_meter;
        
        const positions = mesh.geometry.attributes.position;
        const wgsPositions = _.map(_.range(0, positions.count), i => {
            if (positions.array[i * 3] !== 0 && positions.array[i * 3 + 2] !== 0) {
                return projCoordToWGS84.forward([positions.array[i * 3], -positions.array[i * 3 + 2]]);
            }
            else {
                return [0, 0];
            }
        })
        
        let minLng = wgsPositions[0][0];
        let maxLng = wgsPositions[0][0];
        let minLat = wgsPositions[0][1];
        let maxLat = wgsPositions[0][1];
        
        _.forEach(wgsPositions, pos => {
            if (pos[0] !== 0 && pos[1] !== 0) {
                minLng = Math.min(pos[0], minLng);
                maxLng = Math.max(pos[0], maxLng);
                minLat = Math.min(pos[1], minLat);
                maxLat = Math.max(pos[1], maxLat);
            }
        })
        
        origin = [
            (maxLng + minLng) * 0.5,
            (maxLat + minLat) * 0.5,
        ]
        
        const originMercatorCoord = mapboxgl.MercatorCoordinate.fromLngLat(origin, 0);
        
        _.forEach(wgsPositions, (pos, i) => {
            if (pos[0] !== 0 && pos[1] !== 0) {
                const mercatorCoord = mapboxgl.MercatorCoordinate.fromLngLat(pos, positions.array[i * 3 + 1] * toMeter);
                
                positions.set([
                    (mercatorCoord.x - originMercatorCoord.x), 
                    (mercatorCoord.z - originMercatorCoord.z), 
                    (mercatorCoord.y - originMercatorCoord.y), 
                ], i * 3);
            }
        })
        
        mesh.position.set(originMercatorCoord.x, originMercatorCoord.z, originMercatorCoord.y);

    }
    
    function projectFromMapboxMercator() {
        
    }
    
    function projectToCoordinateSystem(toProjection) {
        const proj = proj4(projection, toProjection);
        
    }
    
    function project(toProjection) {
        if (toProjection === projection) return;

        if (toProjection === 'Mapbox') {
            projectToMapboxMercator();
        }
        else {
            if (projection === 'Mapbox') {
                projectFromMapboxMercator();
            }
            else {
                projectToCoordinateSystem(toProjection);
            }
        }
        
        mesh.geometry.attributes.position.needsUpdate = true;
        projection = toProjection;
    }
    
    mesh.project = project;
    
    return mesh;
}

export default GeoreferencedMeshWrapper;