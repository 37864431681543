import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import * as THREE from "three";

class Custom3DLayer {
    // static s_baseRotation = [Math.PI / 2, 0, 0];

    /**
     * Mapbox Custom 3D Mapbox Layer constructor
     * @param {[String]} options.id  map ID
     * @param {[String]} options.origin center of projection
     */
    constructor(options) {
        this.id = options.id;
        this.type = 'custom';
        this.renderingMode = '3d';

        this.camera = new THREE.PerspectiveCamera();
        this.scene = new THREE.Scene();
        
        this.addModel = this.addModel.bind(this);
        this.removeModel = this.removeModel.bind(this);
        
        this.onAdd = this.onAdd.bind(this);
        this.render = this.render.bind(this);

        this.setupLights(this.scene);
        // this.scene.add(new THREE.CameraHelper(this.camera))
        // 

        this.paused = false;
    }
    
    setupLights() {
        const pointLight0 = new THREE.PointLight(0xffffff, 2);

        this.light = pointLight0;
        this.scene.add(pointLight0);
    }
    
    setLightPositions(origin) {
        const meterUnit = origin.meterInMercatorCoordinateUnits()
        const bearing = this.map.getBearing();
        
        const localPos = new THREE.Vector3(meterUnit * 0, 0, meterUnit * 500);
        const rotation = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), -bearing / 180 * Math.PI);
        
        localPos.applyMatrix4(rotation);
        
        this.light.position.set(origin.x + localPos.x, origin.z + meterUnit * 300, origin.y + localPos.z);
        this.light.distance = meterUnit * 2000;
    }
    
    // updateCamera(center) {
    //     const cameraOptions = this.map.getFreeCameraOptions();
    //     const canvas = this.map.getCanvas();
	// 	const vpWidth = canvas.width;
	// 	const vpHeight = canvas.height;
	// 	const aspect = vpWidth / vpHeight;

    //     const originInMercator = mapboxgl.MercatorCoordinate.fromLngLat(center, 0);
    //     const meter = originInMercator.meterInMercatorCoordinateUnits();
        
    //     this.camera.fov = this.map.transform.fov;
    //     this.camera.aspect = aspect
    //     this.camera.near = 0.01 * meter;
    //     this.camera.far = 1000 * meter;
    //     // this.camera.position.set(
    //     //     cameraOptions.position.x,
    //     //     cameraOptions.position.y,
    //     //     cameraOptions.position.z,
    //     //     // cameraOptions.position.x,
    //     //     // cameraOptions.position.y,
    //     //     // cameraOptions.position.z,
    //     // )
    //     this.camera.quaternion.fromArray(cameraOptions.orientation);

    //     this.camera.updateMatrixWorld();
    //     this.camera.updateProjectionMatrix();
    // }
    
    addModel(model) {
        this.scene.add(model);
    }
    
    removeModel(model) {
        this.scene.remove(model);
    }
    
    onAdd(map, gl) {
        this.renderer = new THREE.WebGLRenderer({
            canvas: map.getCanvas(),
            context: gl,
            antialias: true,
        })
        
        this.renderer.autoClear = false;
        this.map = map;
    }
    
    render(gl, matrix) {
        const center = this.map.getCenter();
        const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(center, 0);
        
        this.setLightPositions(modelAsMercatorCoordinate);
        
        const m = new THREE.Matrix4().fromArray(matrix);
        const l = new THREE.Matrix4()
            .makeTranslation(
                modelAsMercatorCoordinate.x,
                modelAsMercatorCoordinate.z,
                modelAsMercatorCoordinate.y,
            )

        const rot = new THREE.Matrix4().makeBasis(
            new THREE.Vector3(1, 0, 0),
            new THREE.Vector3(0, 0, 1),
            new THREE.Vector3(0, 1, 0),
        )

        this.camera.position.set(
            modelAsMercatorCoordinate.x,
            modelAsMercatorCoordinate.z,
            modelAsMercatorCoordinate.y,
        )
            
        this.camera.projectionMatrix = m.multiply(rot).multiply(l);
        
        if (!this.paused) {
            this.renderer.resetState();
            this.renderer.render(this.scene, this.camera);
        }
    }
    
}

export default Custom3DLayer;