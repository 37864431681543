import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import proj4 from 'proj4';

proj4.defs([
    [
        'ESRI:102711:Feet',
        '+proj=tmerc +lat_0=38.83333333333334 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +datum=NAD83 +to_meter=0.3048006096012192 +no_defs ',
    ]
]);

proj4.defs([
    [
        'ESRI:102711:Meters',
        '+proj=tmerc +lat_0=38.83333333333334 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +datum=NAD83 +to_meter=1 +no_defs ',
    ]
]);

function transformGeometryToMapboxCoordinateSystem(proj, origin, model) {
    const projCoordToWGS84 = proj4(proj, "WGS84");
    const toMeter = proj4(proj).oProj.to_meter;

    const positions = model.geometry.attributes.position;
    const originMercatorCoord = mapboxgl.MercatorCoordinate.fromLngLat(origin, 0);
    
    for (let i = 0, len = positions.count; i < len; ++i) {
        const wgs84Coord = projCoordToWGS84.forward([positions.array[i * 3], -positions.array[i * 3 + 2]]);
        const mercatorCoord = mapboxgl.MercatorCoordinate.fromLngLat(wgs84Coord, positions.array[i * 3 + 1] * toMeter);
        
        positions.set([
            (mercatorCoord.x - originMercatorCoord.x), 
            (mercatorCoord.y - originMercatorCoord.y), 
            (mercatorCoord.z - originMercatorCoord.z), 
            // (mercatorCoord.x), 
            // (mercatorCoord.y), 
            // (mercatorCoord.z), 
        ], i * 3);
    }
    
    positions.needsUpdate = true;
}

export {
    transformGeometryToMapboxCoordinateSystem,
}