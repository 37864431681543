import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Flex from 'App/styled/Flex';
import FlexFill from 'App/styled/FlexFill';
import FormGroup from 'App/styled/FormGroup';
import FormText from 'App/styled/FormText';

import { useSharedContext, setSharedContext } from 'Lib/hooks/useSharedContext';

import contexts from 'App/contexts';
import config from 'App/config'

import Menu from './Menu';

import fixWebmDuration from "fix-webm-duration";

const Checkbox = styled.input.attrs({
    type: 'checkbox',
})`
    margin: 0px 4px;
    vertical-align: middle;

    &:hover {
        cursor: ${props => props.disabled ? 'default' : 'pointer'};
    }
`

const UploadField = styled.input.attrs({
    type: 'file'
})`
    background-color: white;
    border-radius: 4px;
    color: black;
`

function TerminalModelToggle({ forAttribute, label }) {
    const context = useSharedContext(contexts.TerminalModel);
    
    return React.useMemo(() => (
        <FormGroup>
            <Checkbox 
                checked={context[forAttribute]}
                disabled={context.model === null}
                onChange={() => {
                    setSharedContext(contexts.TerminalModel, {
                        [forAttribute]: !context[forAttribute],
                    })
                }}
            />
            <FormText>{label}</FormText>
        </FormGroup>
    ), [context[forAttribute], context.model, label]);
}

const PlayButton = styled.svg.attrs(props => ({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 16 16",
    children: props.isPaused
        ? <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"/>
        : <path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"/>
}))`
    fill: ${props => props.disabled ? 'lightgray !important' : '#52b788'};
    width: 32px;
    height: 32px;
    vertical-align: middle;

    &:hover {
        fill: #e63946;
        cursor: ${props => props.disabled ? 'default' : 'pointer'};
    }

    transition: fill;
    transition-duration: 0.3s;
`

const ProgressBar = styled.input.attrs({
    type: 'range',
})`
    flex-grow: 1;
    vertical-align: middle;
`

function AnimationController(props) {
    const { isPaused, animation, progress, maxProgress }= useSharedContext(contexts.FloodAnimation);
    
    return React.useMemo(() => (
        <FlexFill style={{ margin: '0px 8px' }} >
            <Flex>
                <PlayButton 
                    isPaused={isPaused}
                    disabled={animation === null}
                    onClick={() => {
                        setSharedContext(contexts.FloodAnimation, {
                            isPaused: !isPaused,
                        })
                    }}
                />

                <ProgressBar 
                    value={progress} 
                    min={0} 
                    max={maxProgress} 
                    step={0.03} 
                    disabled={animation === null}
                    onChange={e => {
                        setSharedContext(contexts.FloodAnimation, {
                            isPaused: true,
                            progress: e.target.value,
                        })
                    }}
                />
            </Flex>
        </FlexFill>
    ), [isPaused, animation, progress, maxProgress])
}

const Select = styled.select`
    display: inline-block;
    width: fit-content;
    font-size: 12px;
    padding: 4px;
    vertical-align: middle;
    border-radius: 4px;
`

const Button = styled.button.attrs({
    className: 'btn btn-light'
})`
    display: inline-block;
    width: fit-content;
    height: fit-content;
    padding: 4px;
    font-size: 12px;
    border: 0;
    border-radius: 4px;

    &:hover {
        background-color: #D8E3E9;
    }

    &:active {
        background-color: #3C78B9;
        color: white;
    }
`

function EventSelect(props) {
    const { events, eventName } = useSharedContext(contexts.FloodEvent);
    
    return React.useMemo(() => (
        <FormGroup >
            <FormText>Event: </FormText>
            <Select 
                onChange={e => {
                    setSharedContext(contexts.FloodEvent, {
                        eventName: e.target.value,
                    });
                }} 
                value={eventName}
            >
                <option value={'current'}>Current</option>
                <option disabled>───</option>
                {
                    events.map((e, i) => <option key={i} value={e}>{e[0].toUpperCase() + e.slice(1)}</option>)
                }
                <option disabled>───</option>
                <option style={{ color: 'lightgray'}} value={'upload'} disabled>Uploaded</option>
            </Select>
        </FormGroup>
    ), [events, eventName])
}

function ExaggerationSelect(props) {
    const exaggerationLevels = [1.0, 1.1, 1.25, 1.5, 2.0];

    const { exaggeration }= useSharedContext(contexts.FloodModel);

    return React.useMemo(() => (
        <FormGroup>
            <FormText>Safety Factor: </FormText>
            <Select 
                onChange={e => {
                    setSharedContext(contexts.FloodModel, {
                        exaggeration: parseFloat(e.target.value),
                    });
                }} 
                value={exaggeration}
            >
                {
                    exaggerationLevels.map((e, i) => <option key={i} value={e}>{`${(e * 100).toFixed(0)}%`}</option>)
                }
            </Select>
        </FormGroup>
    ), [exaggeration]);
}

function ExportAnimationButton(props) {
    const { map } = useSharedContext(contexts.Map);
    const { animation, duration } = useSharedContext(contexts.FloodAnimation);

    const recordAndExport = React.useCallback((e) => {
        const canvas = map.getCanvas();

        const videoStream = canvas.captureStream(30);
        const mediaRecorder = new MediaRecorder(videoStream);
        
        const chunks = [];
        mediaRecorder.ondataavailable = e => {
            if (e.data && e.data.size > 0) {
                chunks.push(e.data);
            }
        }
        
        mediaRecorder.onstop = e => {
            fixWebmDuration(new Blob(chunks, { 'type': 'video/webm', }), duration * 1000)
                .then(blob => {
                    var videoURL = URL.createObjectURL(blob);
                    var tag = document.createElement('a');

                    tag.href = videoURL;
                    tag.target = 'blank';
                    tag.download = 'animation.webm';
                    
                    document.body.appendChild(tag);
                    tag.click();
                    document.body.removeChild(tag);
                })
        }
        
        setSharedContext(contexts.LoadingScreen, {
            progress: 0,
            message: 'Recording animation...',
            active: true,
        })
        
        mediaRecorder.start();
        animation.setFrame(0);
        animation.play();
        
        const intervalId = setInterval(() => {
            setSharedContext(contexts.LoadingScreen, {
                progress: animation.frame / animation.frameCount,
                message: 'Recording animation...',
            })
        })
        
        setTimeout(() => {
            mediaRecorder.stop();
            clearInterval(intervalId);
            setSharedContext(contexts.LoadingScreen, {
                active: false,
            });
        }, duration * 1000);

    }, [map, animation, duration]);
    
    return (    
        <FormGroup>
            <Button onClick={recordAndExport}>
                Export As Video
            </Button>
        </FormGroup>
    )
}

const ControllerContainer = styled(Flex)`
    width: 100%;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.95);
    z-index: 1000;
    align-items: center;
    font-size: 12px;
    color: white;
    // padding: 0px 6px;
`

const Timestamp = styled.div`
	width: fit-content;
	height: fit-content;
	z-index: 10;
	display: ${props => props.children ? 'inline-block' : 'none'};
	color: white;
	margin: 0px 8px;
	font-size: 14px;
`

function AnimationTimestamp() {
	const { startTime, } = useSharedContext(contexts.FloodEvent);
	const { progress, } = useSharedContext(contexts.FloodAnimation);

	return React.useMemo(() => {
		if (startTime) {
			return (
				<Timestamp>
					{ moment(startTime, config.TimeFormat).add(progress, 'hour').format(config.TimeFormat) }
				</Timestamp>
			)
		}
		else {
			return null;
		}
	}, [progress, startTime])
}

const ScaleGroup = styled.div`
	display: block;
	height: fit-content;
	width: fit-content;
	vertical-align: middle;
	text-align: right;
`

const ScaleColor = styled.div`
    display: inline-block;
	width: 12px;
	height: 20px;
	background-color: ${props => props.color};
`

function ColorScale({ colors }) {
	return (
        <ScaleGroup>
            <ScaleColor color={'rgba(0, 21, 179, 0.6)'} />
            <ScaleColor color={'rgba(0, 63, 198, 0.6)'} />
            <ScaleColor color={'rgba(0, 112, 217, 0.6)'} />
            <ScaleColor color={'rgba(0, 169, 236, 0.6)'} />
            <ScaleColor color={'rgba(0, 234, 255, 0.6)'} />
        </ScaleGroup>
	)
}

function ColorScaleSelect(props) {
    const { colorScale } = useSharedContext(contexts.FloodEvent);
    
    return React.useMemo(() => (
        <FormGroup >
            <FormText>Flood Model Color Scale: </FormText>
            <Select 
                onChange={e => {
                    setSharedContext(contexts.FloodEvent, {
                        colorScale: e.target.value,
                    });
                }} 
                value={colorScale}
            >
                <option value={'blue'}>Blue</option>
                <option value={'muddy'}>Muddy</option>
            </Select>
        </FormGroup>
    ), [colorScale])
}

function ModelController(props) {
    return React.useMemo(() => (
        <Flex style={{ flexDirection: 'column' }}>
            <ControllerContainer>
                {/* <Menu /> */}

                <TerminalModelToggle forAttribute='show3DModel' label='3D Model'/>
                <TerminalModelToggle forAttribute='showWireframe' label='Wireframe'/>
                <UploadField onChange={e => {
                    setSharedContext(contexts.FloodEvent, {
                        eventName: 'upload',
                        uploadFile: e.target.files[0],
                    })
                    
                    e.target.value = "";
                }} placeholder='Upload Local Flood Model'/>
                <ExportAnimationButton />
            </ControllerContainer>
            <ControllerContainer style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)', }}>
                <AnimationController />
                <AnimationTimestamp />
            </ControllerContainer>
            <ControllerContainer style={{ position: 'absolute', left: 0, bottom: 0 }}>
                <EventSelect />
                <ExaggerationSelect />
                <ColorScaleSelect />
            </ControllerContainer>
        </Flex>
    ), [])
}

export default ModelController;