import React from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import useChainedPromise from 'Lib/hooks/useChainedPromise';

const assetSource = {
	type: 'vector',
	url: 'mapbox://yaoc1996.cstkv9rm',
}

const demSource = {
	'type': 'raster-dem',
	'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
	// 'url': 'mapbox://yaoc1996.1qhncwbv',
	// 'tileSize': 256,
	// 'maxzoom': 24,
}

const assetExtrusionLayer = {
	'id': 'hoboken-asset-layer',
	'type': 'fill-extrusion',
	'source': 'hoboken-assets',
	'source-layer': 'HobokenBaseAssets_20211102-3m4v9w',
	'paint': {
		// 'line-color': '#000000',
		// 'line-width': 2,
		
		'fill-extrusion-color': [
			'case',
			['boolean', ['feature-state', 'focus'], false], '#f28482',
			['boolean', ['feature-state', 'hover'], false], '#f5cac3',
			'#FFFFFF',
		],
		'fill-extrusion-height': ['*', ['get', 'Z_Impact'], 0.3048],
		'fill-extrusion-base': 0, //['*', ['get', 'Z_Base'], feetToMeter],
		// 'fill-extrusion-base': 0,
		'fill-extrusion-opacity': 1,
	}
}

function loadMap(container, options) {
	const map = new mapboxgl.Map({
		container,
		...options,
	})

	return new Promise((resolve, reject) => {
		map.on('style.load', () => {
			map.addSource('hoboken-assets', assetSource)
			map.addSource('mapbox-dem', demSource);
			map.addLayer(assetExtrusionLayer);
			// this.map.setTerrain({'source': 'mapbox-dem', 'exaggeration': 1.0, });

			resolve(map);
		})
	})
}

function useMapbox(ref, options, preLoad, deps=[]) {
	const chainedPromise = useChainedPromise();

	React.useEffect(() => {
		const onLoad = preLoad();
		
		chainedPromise
			.then(() => loadMap(ref.current, options))
			.then(map => {
				const onDispose = onLoad(map);
				const onResize = () => map.resize();
				window.addEventListener('resize', onResize);
		
				return [map, onDispose, onResize];
			})
		
		return () => {
			chainedPromise
				.then(([map, onDispose, onResize]) => {
					window.removeEventListener('resize', onResize);
					if (onDispose) onDispose();
					map.remove();
				})
		}
	}, [ref.current, ...deps])
}

export default useMapbox;