import React from 'react'
import * as THREE from "three";
import _ from 'lodash';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import { useSharedContext, useSetSharedContext, setSharedContext } from 'Lib/hooks/useSharedContext';

import { PanoramaFloodElevationShaders, PanoramaShaders } from 'App/shaders';
import GeoreferencedMeshWrapper from 'Lib/georeferenced-mesh-wrapper';

import { getPanoramicInfo, getPanoramicImage, getPanoramicDepth } from 'App/actions';

import contexts from 'App/contexts';

function loadTexture(path) {
	return new Promise(resolve => {
		new THREE.TextureLoader().load(path, resolve);
	})
}

function usePanorama(location, onLoad, deps=[]) {
	React.useEffect(() => {
		if (location) {
			setSharedContext(contexts.LoadingScreen, {
				active: true,
				message: 'Loading Panorama View...',
			})
			
			console.log(location)

			let promise = Promise.all([
				getPanoramicInfo(location),
				getPanoramicImage(location, {
					onDownloadProgress: e => {
						setSharedContext(contexts.LoadingScreen, {
							progress: e.loaded / e.total,
						})
					},
				}),
				getPanoramicDepth(location),
			])
			
			promise = promise.then(res => {
				return new Promise(resolve => {
					const info = res[0];
					
					Promise.all(res.slice(1, 3).map(img => {
						const blob = new Blob([img]);
						const url = URL.createObjectURL(blob);
						const tex = new THREE.TextureLoader().loadAsync(url);
						return tex;
					}))
						.then(textures => {
							resolve([info, textures])
						})
				})
			})
				.then(([info, textures]) => {
					const res = [textures, onLoad(info, textures)];
					setSharedContext(contexts.LoadingScreen, {
						active: false,
					})
					
					return res;
				})
			
			return () => {
				promise.then(([textures, onDispose]) => {
					onDispose();
					textures.forEach(t => t.dispose());
				})
			}
		}
	}, deps)
}

function Panoramic({ mapRef }) {
	const [state, setState] = React.useReducer((state, newState) => ({ ...state, ...newState, }), {
		scene: null,
		camera: null,
		renderer: null,
		controls: null,
		info: null,
		panorama: null,
	});

	const { mode, } = useSharedContext(contexts.Map);
	const { model, } = useSharedContext(contexts.FloodModel);
	const { location } = useSharedContext(contexts.Panoramic);
	const { scene, camera, renderer, controls, panorama, info, } = state;
	
	React.useEffect(() => {
		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(75, 1.0, 0.1, 3000);
		const renderer = new THREE.WebGLRenderer();
		
		const controls = new OrbitControls(camera, renderer.domElement);
		controls.enableZoom = false;
		controls.panSpeed = 0;
		controls.rotateSpeed = -0.5;

		function zoom(e) {
			if (e.target === renderer.domElement) {
				if (e.deltaY < 0) {
					camera.fov -= 3;
				}
				else {
					camera.fov += 3;
				}
				
				if (camera.fov < 30) {
					camera.fov = 30
				}
				else if (camera.fov > 120) {
					camera.fov = 120;
				}

				camera.updateProjectionMatrix();
			}
		}

		window.addEventListener('wheel', zoom, true);

		setState({
			scene,
			camera,
			renderer,
			controls,
		})
		
		return () => {
			window.removeEventListener('wheel', zoom, true);
		}
	}, [])
	
	React.useEffect(() => {
		if (mapRef.current && renderer) {
			const parentElement = mapRef.current;
			parentElement.innerHTML = "";
			parentElement.appendChild(renderer.domElement);
		}
	}, [mapRef, renderer])
	
	React.useEffect(() => {
		if (mapRef.current && renderer && camera) {
			const parentElement = mapRef.current;

			function onResize() {
				const { offsetWidth: width, offsetHeight: height } = parentElement;
				
				camera.aspect = width / height;
				camera.updateProjectionMatrix();
				
				renderer.setSize(width, height);
			}

			onResize();
			window.addEventListener('resize', onResize);
			
			return () => {
				window.removeEventListener('resize', onResize);
			}
		}
	}, [mapRef, renderer, camera])
	
	usePanorama(location, (info, [imageTexture, distanceTexture]) => {
		const { angleOfRotation, axisOfRotation } = info;
		
		distanceTexture.magFilter = THREE.NearestFilter;
		distanceTexture.minFilter = THREE.NearestFilter;
		distanceTexture.needsUpdate = true;

		const geometry = new THREE.SphereGeometry(300);
		geometry.scale(-1, 1, 1);
		
		const material = new THREE.ShaderMaterial({ 
			uniforms: {
				panoramaTexture: {
					value: imageTexture,
				},
				distanceTexture: {
					value: distanceTexture,
				}
			},
			side: THREE.DoubleSide,
			vertexShader: PanoramaShaders.vertex(),
			fragmentShader: PanoramaShaders.fragment(),
		});
		
		material.extensions.fragDepth = true;

		const sphere = new THREE.Mesh(geometry, material);
		sphere.rotateOnAxis(
			new THREE.Vector3(
				// axisOfRotation[0], 
				// axisOfRotation[1], 
				// axisOfRotation[2], 
				0, 1, 0,
			)
			, (-90) / 180 * Math.PI
			// , (90) / 180 * Math.PI
		)
		
		setState({
			panorama: sphere,
			info,
		})
		
		return () => {
			geometry.dispose();
			material.dispose();
		}
	}, [location])
	
	React.useEffect(() => {
		if (camera && panorama && controls && info) {
			const origin = info.scannerPos;

			camera.position.set(origin[0], origin[1], -origin[2]-0.001);
			panorama.position.set(origin[0], origin[1], -origin[2]);
			controls.target.set(origin[0], origin[1], -origin[2]);
		}
	}, [camera, panorama, controls, info])
	
	React.useEffect(() => {
		if (scene && panorama) {
			scene.add(panorama);
			
			return () => {
				scene.remove(panorama);
			}
		}
	}, [scene, panorama])
	
	React.useEffect(() => {
		if (scene && camera && renderer && controls && mode === 'panorama-view') {
			let running = true;
			let prevTime = 0;
			let fps = 90;

			var animate = function (time) {
				if (!running) return;
				
				if (time - prevTime > 1000 / fps) {
					prevTime = time;
					controls.update();
					renderer.render( scene, camera );
				}

				requestAnimationFrame( animate );
			};

			animate();
			
			return () => {
				running = false;
			}
		}
	}, [scene, camera, renderer, controls, mode])
	
	// React.useEffect(() => {
	// 	setSharedContext(contexts.FloodAnimation, {
	// 		isPaused: true,
	// 		progress: 35.52,
	// 	})
	// }, [])
	
	React.useEffect(() => {
		if (model && scene) {
			const geometry = new THREE.BufferGeometry();
			const material = new THREE.ShaderMaterial({
				uniforms: model.model.material.uniforms,
				vertexShader: PanoramaFloodElevationShaders.vertex(),
				fragmentShader: PanoramaFloodElevationShaders.fragment(),
				transparent: true,
				side: THREE.DoubleSide,
			})

			material.extensions.fragDepth = true;
			
			geometry.setAttribute('position', model.model.geometry.attributes.rawPosition);
			geometry.setIndex(model.model.geometry.index);
			
			geometry.setAttribute('floodDepth', model.model.geometry.attributes.floodDepth);
			geometry.setAttribute('floodDepthNext', model.model.geometry.attributes.floodDepthNext);

			const floodGrid = new THREE.Mesh(geometry, material)
			
			scene.add(floodGrid);
			
			return () => {
				scene.remove(floodGrid);
			}
		}
	}, [model, scene])
	
	return null;
}

export default Panoramic;