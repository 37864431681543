class Animation {
	constructor() {
		this.request = null;
		this.frame = 0;
		
		this.isActive = this.isActive.bind(this);
		this.create = this.create.bind(this);
		this.dispose = this.dispose.bind(this);
		this.play = this.play.bind(this);
		this.pause = this.pause.bind(this);
		this.setFrame = this.setFrame.bind(this);
		this.frameUpdate = this.frameUpdate.bind(this);
		this.requestAnimationFrameCallback = this.requestAnimationFrameCallback.bind(this);
	}
	
	isActive() {
		return this.request !== null;
	}
	
	create(options) {
		this.duration = options.duration * 1000;
		this.frameCount = options.frameCount;
		this.onUpdate = options.onUpdate;
	}
	
	dispose() {
		this.pause();
		this.duration = 0;
		this.frameCount = 0;
		this.onUpdate = null;
	}
	
	play() {
		if (this.request === null) {
			this.request = {
				id: requestAnimationFrame(this.requestAnimationFrameCallback) ,
				frame: null,
			}
		}
	}
	
	pause() {
		if (this.request !== null) {
			cancelAnimationFrame(this.request.id);
			this.request = null;
		}
	}

	setFrame(frame) {
		this.frame = frame - Math.floor(frame / this.frameCount) * this.frameCount;
		
		if (!this.request) {
			this.frameUpdate(this.frame);;
		}
	}
	
	frameUpdate(frame) {
		if (this.onUpdate != null) {
			this.onUpdate(frame)
		}
	}
	
	requestAnimationFrameCallback(frame) {
		const delta = (frame - this.request.frame) / this.duration * this.frameCount;

		if (this.request.frame != null && delta < 1.0) {
			this.frame += delta;
			
			if (this.frame >= this.frameCount) {
				this.frame -= this.frameCount;
			}

			this.frameUpdate(this.frame);
		}

		this.request = {
			id: requestAnimationFrame(this.requestAnimationFrameCallback) ,
			frame: frame,
		}
	}
}

export default Animation;