import React from 'react';
import _ from 'lodash';

import { AbstractMethodError } from 'App/errors';

function SharedContext(initial) {
	const context = React.createContext(initial);
	const setStateRef = {
        setState: () => { throw new AbstractMethodError(); }
    }
	
	function ContextProvider(props) {
        const [state, setState] = React.useState(initial);
		setStateRef.setState = setState;
        
		return <context.Provider value={state}>{ props.children }</context.Provider>
	}	
	return {
		context,
		setStateRef,
		ContextProvider,
	}
}

function setSharedContext(sharedContext, newState) {
    sharedContext.setStateRef.setState(state => ({ ...state, ...newState, }));
}

function useSharedContext(sharedContext) {
	return React.useContext(sharedContext.context);
}

function useSetSharedContext(sharedContext, newState, deps=[], pred=null) {
    React.useEffect(() => {
        if (!pred || pred()) {
            setSharedContext(sharedContext, newState);
        }
    }, deps);
}

function withSharedContextWrapper(initial) {
    const sharedContext = SharedContext(initial);

    return [sharedContext, Component => {
        return props => (
            <sharedContext.ContextProvider>
                <Component {...props} />
            </sharedContext.ContextProvider>
        )
    }];
}

function withSharedContextsWrapper(options) {
    const [keys, vals] = _.zip(...Object.entries(options));
    const [contexts, wrappers] = _.zip(...vals.map(val => withSharedContextWrapper(val)));
    return [Object.fromEntries(_.zip(keys, contexts)), Component => _.flow(wrappers)(Component)];
}

export {
    setSharedContext,
    useSharedContext,
    useSetSharedContext,
    withSharedContextWrapper,
    withSharedContextsWrapper,
}

export default useSharedContext;