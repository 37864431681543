const linearDepthToZBufferValue = `
    float lDepthToZBuffer(float d) {
        return ((3000.0 + 0.1 - 2.0 * 0.1 * 3000.0 / d) / (3000.0 - 0.1) + 1.0) / 2.0;
    }
`

const FloodElevationShaders = {
    vertex: function() {
        return `
            vec3 hsv2rgb(vec3 c)
            {
                vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
                vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
                return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
            }

            uniform float time;
            uniform float exaggeration;
            uniform float toMeter;

            uniform float maxHeight;
            uniform float colorSteps;

            uniform float maxHue;
            uniform float minHue;
            uniform float maxSat;
            uniform float minSat;
            uniform float maxVal;
            uniform float minVal;
        
            uniform float waterLevel;

            attribute float floodDepth;
            attribute float floodDepthNext;
            attribute float meterToMercatorAltitude;

            varying float indicator;
            varying vec3 color;

            void main() {
                float height = floodDepth + time * (floodDepthNext - floodDepth);

                float f = (clamp(height, 0.0, maxHeight) / maxHeight * colorSteps) / colorSteps;
                
                vec3 hsv = vec3(
                    f * (maxHue - minHue) + minHue,
                    f * (maxSat - minSat) + minSat,
                    f * (maxVal - minVal) + minVal
                );
                
                color = hsv2rgb(hsv);

                indicator = (height <= 0.01) ? -1.0 : 0.0;
                
                vec3 pos = vec3(position.x, height * toMeter * meterToMercatorAltitude * exaggeration + position.y, position.z);
                
                // if (indicator < 0.0) {
                //     pos.x = 0.0;
                //     pos.y = -10000000000.0;
                //     pos.z = 0.0;
                // }

                vec4 mvPos = modelViewMatrix * vec4(pos, 1.0);
                
                gl_Position = projectionMatrix * mvPos;
            }
        `
    },
    fragment: function() {
        return `
            ${linearDepthToZBufferValue}

            uniform float opacity;

            varying float indicator;
            varying vec3 color;

            void main() {
                if (indicator < 0.0) {
                    discard;
                }

                gl_FragColor = vec4(color, opacity);
            }
        `
    }
}

const PanoramaFloodElevationShaders = {
    vertex: function() {
        return `
            vec3 hsv2rgb(vec3 c)
            {
                vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
                vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
                return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
            }

            uniform float time;
            uniform float exaggeration;

            uniform float maxHeight;
            uniform float colorSteps;

            uniform float maxHue;
            uniform float minHue;
            uniform float maxSat;
            uniform float minSat;
            uniform float maxVal;
            uniform float minVal;
        
            attribute float floodDepth;
            attribute float floodDepthNext;

            varying float indicator;
            varying vec3 color;
            varying float distance;

            void main() {
                float height = floodDepth + time * (floodDepthNext - floodDepth);

                float f = (clamp(height, 0.0, maxHeight) / maxHeight * colorSteps) / colorSteps;
                
                vec3 hsv = vec3(
                    f * (maxHue - minHue) + minHue,
                    f * (maxSat - minSat) + minSat,
                    f * (maxVal - minVal) + minVal
                );
                
                color = hsv2rgb(hsv);

                indicator = (height <= 0.01) ? -10.0 : 0.0;
                
                vec3 pos = vec3(position.x, height * exaggeration + position.y, position.z);
                vec4 mvPos = modelViewMatrix * vec4(pos, 1.0);
                
                distance = clamp(sqrt(mvPos.x * mvPos.x + mvPos.y * mvPos.y + mvPos.z * mvPos.z), 0.1, 3000.0);
                
                gl_Position = projectionMatrix * mvPos;
            }
        `
    },
    fragment: function() {
        return `
            ${linearDepthToZBufferValue}

            uniform float opacity;

            varying float indicator;
            varying vec3 color;
            varying float distance;

            void main() {
                if (indicator < 0.0) {
                    discard;
                }

                gl_FragColor = vec4(color, opacity);
                gl_FragDepth = lDepthToZBuffer(distance);
            }
        `
    }
}

const PanoramaShaders = {
    vertex: function() {
        return `
            varying vec2 vUV;

            void main() {
                vUV = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `
    },
    fragment: function() {
        return `
            ${linearDepthToZBufferValue}
        
            // float decodeElevation(vec4 col) {
            //     return (col.b * 255.0 + col.g * 65025.0 + col.r * 16581375.0) / 100.0 - 100000.0;
            // }
        
            float decodeDistance(vec4 col) {
                return (col.b * 255.0 + col.g * 65025.0 + col.r * 16581375.0) / 100.0;
            }

            uniform sampler2D panoramaTexture;
            uniform sampler2D distanceTexture;

            varying vec2 vUV;

            void main() {
                vec4 color = texture2D(panoramaTexture, vUV);
                vec4 distance = texture2D(distanceTexture, vUV);
                
                float e = decodeDistance(distance);
                
                e = lDepthToZBuffer(e);

                gl_FragColor = color;
                gl_FragDepth = e;
            }
        `
    }
}

export {
    FloodElevationShaders,
    PanoramaFloodElevationShaders,
    PanoramaShaders,
}