import { withSharedContextsWrapper } from "Lib/hooks/useSharedContext";

const contextDefinitions = {
	Development: {},
	Navbar: {
		tabs: ['Dashboard', 'Export', '|', 'Logout'],
		currentTab: 'Dashboard',
	},
	User: {
		user: null,
		token: null,
	},
	LoadingScreen: {
		active: false,
		message: '',
		progress: null,
	},
	Map: {
		mode: 'map-view',
		map: null,	
		customLayer: null,
	},
	TerminalModel: {
		model: null,
		show3DModel: true,
		showWireframe: false,
	},
	FloodModel: {
		model: null,
		exaggeration: 1.0,
	},
	FloodAnimation: {
		animation: null,
		duration: 30,
		isPaused: false,
		maxProgress: 1,
		progress: 0,
	},
	FloodEvent: {
		events: [],
		eventName: 'current',
		startTime: null,
		uploadFile: null,
		colorScale: 'blue',
		simulationType: 'storm',
	},
	Assets: {
		selected: null,
		assets: [],
	},
	Hydrograph: {
		data: [],
	},
	Panoramic: {
		location: null,
	}
}

const [contexts, withSharedContexts] = withSharedContextsWrapper(contextDefinitions, contextDefinitions);

export {
    withSharedContexts,
    contexts,
};

export default contexts;