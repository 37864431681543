import * as THREE from "three";

import { AbstractMethodError } from 'App/errors';
import { transformGeometryToMapboxCoordinateSystem } from '../helpers';

class Model {
    constructor() {
        this.convertToVertexColorMode = this.convertToVertexColorMode.bind(this);
        this.convertToDoubleSidedRendering = this.convertToDoubleSidedRendering.bind(this);
    }
    
    dispose() {

    }

    generateWireframe(mesh, options) {
        const index = mesh.geometry.index;
        
        const wireframeGeometry = mesh.geometry.clone();
        const newIndices = Array(index.count * 2);

        for (let i = 0, len = index.count / 3; i < len; ++i) {
            const a = index.array[i * 3];
            const b = index.array[i * 3 + 1];
            const c = index.array[i * 3 + 2];
            
            newIndices[i * 6] = a;
            newIndices[i * 6 + 1] = b;
            newIndices[i * 6 + 2] = b;
            newIndices[i * 6 + 3] = c;
            newIndices[i * 6 + 4] = c;
            newIndices[i * 6 + 5] = a;
        }

        wireframeGeometry.setIndex(newIndices);

        for (const g of wireframeGeometry.groups) {
            g.start *= 2;
            g.count *= 2;
        }

        const wireframeMaterial = new THREE.LineBasicMaterial({
            color: options.wireframeColor,
            linewidth: 1,
            transparent: true,
            opacity: options.wireframeOpacity,
            // depthTest: false,
            // depthWrite: true,
        });
        
        
        const wireframe = new THREE.LineSegments(wireframeGeometry, wireframeMaterial);
        wireframe.position.set(mesh.position.x, mesh.position.y, mesh.position.z);
        wireframe.rotation.set(mesh.rotation.x, mesh.rotation.y, mesh.rotation.z);
        
        return wireframe
    }
    
    
    convertToVertexColorMode() {
        const model = this.getMesh();
        const index = model.geometry.index;
        const groups = model.geometry.groups;
        const materials = model.material;
        
        const colors = new Float32Array(model.geometry.attributes.position.count * 3);

        for (const group of groups) {
            const mat = materials[group.materialIndex];

            mat.vertexColors = true;
            
            for (let j = group.start, len = group.start + group.count; j < len; ++j) {
                const idx = index.array[j];
                colors[idx * 3] = mat.color.r;
                colors[idx * 3 + 1] = mat.color.g;
                colors[idx * 3 + 2] = mat.color.b;
            }
            
        }

        for (const mat of materials) {
            mat.color.r = 1;
            mat.color.g = 1;
            mat.color.b = 1;
        }

        model.geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    }
    
    convertToDoubleSidedRendering() {
        const materials = this.getMesh().material;
        
        try {
            for (const mat of materials) {
                mat.side = THREE.DoubleSide;
            }
        }
        catch(e) {
            materials.side = THREE.DoubleSide;
        }
    }
    
    getMesh() {
        throw new AbstractMethodError("getMesh()");
    }
}

export default Model;