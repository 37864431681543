import styled from 'styled-components';

const Flex = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    background-color: transparent;
    min-height: 0;
    min-width: 0;
`

export default Flex;