import _ from 'lodash';

class AssetManager {
    constructor(options) {
        this.focusedAssetId = null;
        this.hoveredAssetId = null;
        this.activePopup = null;
        this.callback = null;
        
        this.map = options.map;
        this.layerId = options.layerId;
        this.source = options.source;
        this.sourceLayer = options.sourceLayer;
        
        this.init = this.init.bind(this);
        this.mapLayerClick = this.mapLayerClick.bind(this);
        this.assetLayerClick = this.assetLayerClick.bind(this);
        this.assetLayerMouseMove = this.assetLayerMouseMove.bind(this);
        this.assetLayerMouseLeave = this.assetLayerMouseLeave.bind(this);
        this.onAssetClicked = this.onAssetClicked.bind(this);
        this.resolveTargetAsset = this.resolveTargetAsset.bind(this);
        this.focusAsset = this.focusAsset.bind(this);
        this.hoverAsset = this.hoverAsset.bind(this);
        this.cancelHover = this.cancelHover.bind(this);
        
        this.assets = _.reduce(options.assets, (obj, asset) => {
            obj[asset.id] = asset;
            return obj
        }, {});
        
        this.init();
    }
    
    init() {
		this.map.on('click', this.mapLayerClick);
		this.map.on('click', this.layerId, this.assetLayerClick);
		this.map.on('mousemove', this.layerId, this.assetLayerMouseMove);
		this.map.on('mouseleave', this.layerId, this.assetLayerMouseLeave);
    }

    dispose() {
		this.map.off('click', this.mapLayerClick);
		this.map.off('click', this.layerId, this.assetLayerClick);
		this.map.off('mousemove', this.layerId, this.assetLayerMouseMove);
		this.map.off('mouseleave', this.layerId, this.assetLayerMouseLeave);
    }
    
    mapLayerClick(e) {
        if (e.features === undefined) {
            this.callback(null);
        }
    }
    
    assetLayerClick(e) {
        const asset = this.resolveTargetAsset(e);
        
        if (asset !== null) {
            this.callback(asset);
        }
    }
    
    assetLayerMouseMove(e) {
        const asset = this.resolveTargetAsset(e);

        if (asset) {
            this.map.getCanvas().style.cursor = 'pointer';
            this.hoverAsset(asset);
        }
    }
    
    onAssetClicked(cb) {
        this.callback = cb;
    }

    assetLayerMouseLeave(e) {
        this.cancelHover()
    }
    
    resolveTargetAsset(e) {
        if (e.features.length > 0) {
            e.features.sort((a, b) => {
                if (a.properties.Shape_STAr === b.properties.Shape_STAr) {
                    return 0;
                }
                else if (a.properties.Shape_STAr < b.properties.Shape_STAr) {
                    return -1;
                }
                else {
                    return 1;
                }
            })
            
            return this.assets[e.features[0].id];
        }
        
        return null;
    }
    
    focusAsset(asset) {
		if (this.focusedAssetId !== null && (asset === null || this.focusedAssetId !== asset.id)) {
			this.map.setFeatureState(
				{
					source: this.source,
					sourceLayer: this.sourceLayer,
					id: this.focusedAssetId,
				},
				{
					focus: false,
				}
			)
		}
        
        if (asset === null) return;

		const loc = [asset.properties.X, asset.properties.Y];

        this.map
            .flyTo({
                center: loc,
                zoom: 21 - Math.log2(asset.properties.Shape_STAr) / 4,
            });

        this.focusedAssetId = asset.id;

        this.map.setFeatureState(
            {
                source: this.source,
                sourceLayer: this.sourceLayer,
                id: this.focusedAssetId,
            },
            {
                focus: true,
            }
        )
    }
    
    hoverAsset(asset) {
        this.cancelHover();

        this.hoveredAssetId = asset.id;

        this.map.setFeatureState(
            {
                source: this.source,
                sourceLayer: this.sourceLayer,
                id: this.hoveredAssetId,
            },
            {
                hover: true,
            }
        )
    }
    
    cancelHover() {
        if (this.hoveredAssetId !== null) {
            this.map.setFeatureState(
                {
                    source: this.source,
                    sourceLayer: this.sourceLayer,
                    id: this.hoveredAssetId,
                },
                {
                    hover: false,
                }
            )
        }
    }
}

export default AssetManager;