import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import proj4 from 'proj4';
import * as THREE from "three";
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import Model from "./model";
import { FloodElevationShaders } from 'App/shaders';

import GeoreferencedMeshWrapper from 'Lib/georeferenced-mesh-wrapper';
import { checkPointInConvexPolyCW } from 'Lib/geom';

import walls from './walls.json';
import graph from "./graph.json"

function checkIfPointInPolyGroup(polyGroup, point) {
    let c = 0;
    let p1, p2;
    
    for (const poly of polyGroup) {
        for (let i = 0, len = poly.length - 1; i < len; ++i) {
            p1 = poly[i];
            p2 = poly[i + 1];
            
            if (p1[1] > p2[1]) {
                p1 = poly[i + 1];
                p2 = poly[i];
            }
            
            if (point[1] >= p1[1] && point[1] <= p2[1]) {
                const m = (p2[1] - p1[1]) / (p2[0] - p1[0]);
                const b = p2[1] - m * p2[0];
                
                const target = (point[1] - b) / m;
                
                if (target >= point[0]) {
                    ++c;
                }
            }
        }
    }
    
    return (c % 2) > 0;
}

function computeNewModel(baseElevations, waterDepths) {
	waterDepths = waterDepths.map(x => (x > 0.01) ? x + 0.0 : x);

	const visitOrder = _.sortBy(_.range(0, graph.length), i => -(baseElevations[i] + waterDepths[i]));
	const visited = Array(graph.length).fill(false);

    const stack = [];
	
	function spread() {
        while (stack.length > 0) {
            const gridId = stack.pop();

            visited[gridId] = true;
            
            if (waterDepths[gridId] >= 0.01) {
                const waterElevation = baseElevations[gridId] + waterDepths[gridId];
                const neighbors = graph[gridId];

                for (const nbrId of neighbors) {
                    if (!visited[nbrId]) {
                        const nbrWaterElevation = baseElevations[nbrId] + waterDepths[nbrId];
                        
                        if (nbrWaterElevation < waterElevation) {
                            waterDepths[nbrId] = waterElevation - baseElevations[nbrId];
                            stack.push(nbrId);
                        }
                    }
                }
            }
        }
	}
	
	for (let i = 0, len = visitOrder.length; i < len; ++i) {
		if (!visited[visitOrder[i]]) {
            stack.push(visitOrder[i]);
			spread();
		}
	}
	
	return waterDepths;
}


class FloodModel extends Model {
    constructor() {
        super();
        
        this.getMesh = this.getMesh.bind(this);
        this.dispose = this.dispose.bind(this);
        this.queryNearestFloodGridIndex = this.queryNearestFloodGridIndex.bind(this);
        this.queryNearestFloodGridData = this.queryNearestFloodGridData.bind(this);
        this.loadEvent = this.loadEvent.bind(this);

		this.nearestNeighboursCache = {}
    }
    
    getMesh() {
        return this.model;
    }
    
    getAttributes() {
        return this.model.geometry.attributes;
    }
    
    setExaggeration(value) {
        this.model.material.uniforms.exaggeration.value = value;
    }
    
    setTime(value) {
        this.model.material.uniforms.time.value = value;
    }
    
    setFrame(frame) {
        const { floodDepth, floodDepthNext } = this.getAttributes();
			
        floodDepth.array.set(this.heights[frame]);
        floodDepthNext.array.set(this.heights[(frame + 1) % this.heights.length]);
        
        // floodDepth.array.set(computeNewModel(this.baseHeights, this.heights[frame]));
        // floodDepthNext.array.set(computeNewModel(this.baseHeights, this.heights[(frame + 1) % this.heights.length]));
        
        floodDepth.needsUpdate = true;
        floodDepthNext.needsUpdate = true;
    }

    queryNearestFloodGridIndex(loc) {
		const cache = this.nearestNeighboursCache;
		
		const label = String(loc);
		
		let nearestGridIndex = cache[label];
		
		if (!cache[label]) {
            nearestGridIndex = _.minBy(_.range(this.centersLngLat.length), i => {
                const dx = loc[0] - this.centersLngLat[i].lng;
                const dy = loc[1] - this.centersLngLat[i].lat;
                
                return dx * dx + dy * dy;
            });
			cache[label] = nearestGridIndex;
		}
        
        return nearestGridIndex;
    }

    queryNearestFloodGridData(loc) {
        const nearestGridIndex = this.queryNearestFloodGridIndex(loc);
		return _.range(this.heights.length).map(i => this.heights[i][nearestGridIndex] + this.centers[nearestGridIndex][2]);
    }
    
    prepareGrid(options, centersBuffer, roofGeometries) {
        const proj = proj4(options.proj, 'WGS84');
        this.proj = options.proj;
        
        this.centers = _.chunk(centersBuffer, 3);
        
        this.centerLabels = _.map(this.centers, c => {
            const center2D = [c[0], c[1]];
            
            let isUnderRoof = false;
            
            for (const group of roofGeometries) {
                for (const geom of group) {
                    if (checkIfPointInPolyGroup([geom], center2D)) {
                        isUnderRoof = true;
                        break;
                    }
                }
                
                if (isUnderRoof) break;
            }
            
            // return 0;
            return isUnderRoof ? 1 : 0;
        })
        
        _.forEach(this.centers, (c, i) => {
            if (this.centerLabels[i] === 0) {
                const center2D = [c[0], c[1]];
                
                let isUnderRoof = false;
                for (const group of walls.features[0].geometry.coordinates) {
                    if (checkIfPointInPolyGroup(group, center2D)) {
                        isUnderRoof = true;
                        break;
                    }
                }
                
                if (isUnderRoof) {
                    this.centerLabels[i] = 1;
                }
            }
            
            // c[2] = 200;
        })

        this.centersLngLat = _.map(this.centers, c => new mapboxgl.LngLat(...proj.forward([c[0], c[1]])));
        this.centersMercator = _.map(this.centersLngLat, c => mapboxgl.MercatorCoordinate.fromLngLat(c));
        this.baseHeights = _.map(this.centers, x => x[2]);
    }
    
    loadEvent(options, info, centers, mesh, floodDepths, roofGeometries) {
        this.mesh = mesh;
        this.prepareGrid(options, centers, roofGeometries);

        const centerCount = this.centers.length;
        const frameCount = floodDepths.length / centerCount;

        this.heights = _.range(frameCount).map(i => floodDepths.slice(i * centerCount, (i + 1) * centerCount));
        
        for (const height of this.heights) {
            for (let i = 0; i < height.length; ++i) {
                if (this.centerLabels[i] === 1) {
                    height[i] -= info.precipitationExcess;
                }
            }
        }

        const geometry = new THREE.BufferGeometry();
        const material = new THREE.ShaderMaterial({
            uniforms: {
                toMeter: {
                    type: 'float',
                    value: proj4(options.proj).oProj.to_meter,
                },
                maxHeight: {
                    type: 'float',
                    value: 2.0,
                },
                colorSteps: {
                    type: 'float',
                    value: 4,
                },
                maxHue: {
                    type: 'float',
                    value: 233 / 360.0,
                },
                minHue: {
                    type: 'float',
                    value: 185 / 360.0,
                },
                maxSat: {
                    type: 'float',
                    value: 100 / 100,
                },
                minSat: {
                    type: 'float',
                    value: 100 / 100,
                },
                maxVal: {
                    type: 'float',
                    value: 70.0 / 100,
                },
                minVal: {
                    type: 'float',
                    value: 100 / 100,
                },
                time: {
                    type: 'float',
                    value: 0,
                },
                exaggeration: {
                    type: 'float',
                    value: 1.0,
                },
                opacity: {
                    type: 'float',
                    value: 0.6,
                }
            },
            vertexShader: FloodElevationShaders.vertex(),
            fragmentShader: FloodElevationShaders.fragment(),
            transparent: true,
        })
        
        const vertices = new Float32Array(centerCount * 3);
        
        for (let i = 0, len = centerCount; i < len; ++i) {
            vertices[i * 3] = this.centers[i][0];
            vertices[i * 3 + 1] = this.centers[i][2];
            vertices[i * 3 + 2] = -this.centers[i][1];
        }
        
        const meterToMercatorAltitude = _.map(this.centersMercator, coord => coord.meterInMercatorCoordinateUnits());
        
        geometry.setAttribute('rawPosition', new THREE.BufferAttribute(vertices.slice(), 3, false));
        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3, false));
        geometry.setAttribute('meterToMercatorAltitude', new THREE.BufferAttribute(new Float32Array(meterToMercatorAltitude), 1, false));
        geometry.setAttribute('floodDepth', new THREE.BufferAttribute(new Float32Array(Array(centerCount).fill(0)), 1, false));
        geometry.setAttribute('floodDepthNext', new THREE.BufferAttribute(new Float32Array(Array(centerCount).fill(0)), 1, false));
        geometry.setIndex(new THREE.BufferAttribute(this.mesh, 1, false));
        

        this.model = GeoreferencedMeshWrapper(new THREE.Mesh(geometry, material), options.proj);
        this.model.project('Mapbox');
        // this.wireframe = this.generateWireframe(this.model, {
        //     wireframeColor: 0x000000,
        //     wireframeOpacity: 0.1,
        // })
        // this.wireframe.material.depthTest = false;
        this.convertToDoubleSidedRendering();

        return this;
    }
    
    // loadFromEvent(eventOptions, options) {
    //     const reqOptions = {
    //         responseType: 'arraybuffer',
    //     };

    //     return axios.all([
    //         axios.get(eventOptions.jsonSrc),
    //         axios.get(eventOptions.centerSrc, {
    //             ...reqOptions,
    //             onDownloadProgress: options.onGridDownloadProgress,
    //         }),
    //         axios.get(eventOptions.waterLevelSrc, {
    //             ...reqOptions,
    //             onDownloadProgress: options.onWaterLevelDownloadProgress,
    //         }),
    //     ])
    //         .then(res => res.map(x => x.data))
    //         .then(([dataConfig, centersBuffer, heightsBuffer]) => {
    //             const centers = new Float32Array(centersBuffer);
    //             const floodDepths = new Float32Array(heightsBuffer);
    //             const triangles = this.model.geometry.index.array.slice();
                
    //             return this.loadFromData(dataConfig, centers, triangles, floodDepths, options);
    //         })
        
    // }
    
    // loadFromHDF5(hdf5, triangles, options) {
    //     const startTime = hdf5.get('/Plan Data//Plan Information').attrs['Simulation Start Time'];

    //     const centerData = hdf5.get('/Geometry/2D Flow Areas/Perimeter 1/Cells Center Coordinate');
    //     const baseHeightData = hdf5.get("/Geometry/2D Flow Areas/Perimeter 1/Cells Minimum Elevation");
    //     const heightData = hdf5.get("/Results/Unsteady/Output/Output Blocks/Base Output/Unsteady Time Series/2D Flow Areas/Perimeter 1/Water Surface");

    //     const baseHeights = Float32Array.from(baseHeightData.value);
    //     const centers = Float32Array.from(_.flattenDeep(_.zip(_.chunk(centerData.value, 2), baseHeights)));
    //     const heights = Float32Array.from(heightData.value);
        
    //     for (let i = 0, len_i = baseHeights.length; i < len_i; ++i) {
    //         if (isNaN(baseHeights[i])) {
    //             baseHeights[i] = 0;
    //             centers[i * 3] = 0;
    //             centers[i * 3 + 1] = 0;
    //             centers[i * 3 + 2] = 0;
                
    //             for (let j = 0, len_j = heightData.shape[0]; j < len_j; ++j) {
    //                 heights[j * heightData.shape[1] + i] = 0;
    //             }
    //         }
    //     }
        

    //     for (let i = 0, len_i = heightData.shape[0]; i < len_i; ++i) {
    //         let offset = i * heightData.shape[1];

    //         for (let j = 0, len_j = heightData.shape[1]; j < len_j; ++j) {
    //             heights[offset + j] -= baseHeights[j];
    //         }
    //     }

    //     const dataConfig = {
    //         centers: {
    //             shape: [centerData.shape[0], centerData.shape[1] + 1],
    //         },
    //         heights: {
    //             shape: heightData.shape
    //         },
    //         startTime: moment(startTime, 'DDMMMYYYY HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"),
    //     }
        
    //     return this.loadFromData(dataConfig, centers, triangles, heights, options);
    // }
}


export {
    FloodModel,
}