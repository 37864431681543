import React from 'react';

import { useSharedContext, useSetSharedContext } from 'Lib/hooks/useSharedContext';

import useAssets from './useAssets';
import AssetManager from './AssetManager';

import contexts from 'App/contexts';

function Assets({ map }) {
	const { model } = useSharedContext(contexts.FloodModel);
	
	const assets = useAssets(model);

	useSetSharedContext(contexts.Assets, { assets: assets, }, [ assets ]);
	
	return React.useMemo(() => {
		return (
			<React.Fragment>
				<AssetManager map={map} model={model} />
			</React.Fragment>
		)
	}, [map, model]);
}

export default Assets;