import react from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';

import { setSharedContext, useSharedContext } from 'Lib/hooks/useSharedContext';

import FormGroup from 'App/styled/FormGroup';
import FormText from 'App/styled/FormText';

import themes from 'App/themes';
import contexts from 'App/contexts';
import React from 'react';

const Svg = styled.svg`
    &:hover {
        cursor: pointer;
        fill: ${props => props.hoverFill};
    }
`

const SideBar = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 1000px;
    width: 200px;
    background-color: black;
    z-index: 100;
`

function MenuSideBar({}) {
    return (
        <SideBar>
            
        </SideBar>
    )
}

function Menu({}) {
    const { simulationType } = useSharedContext(contexts.FloodEvent);

    React.useEffect(() => {
        ReactDom.render(
            <MenuSideBar />,
        )

    }, [])

    return (
        <FormGroup>
            <Svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" hoverFill={themes[simulationType].main} className="bi bi-list" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </Svg>
        </FormGroup>
    );
}

export default Menu;